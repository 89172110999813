.containerCstor {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerCstorSmall {
    display: none;
}

.containerCstor .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerCstor .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-left: 3px;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerCstor .playbuybutton {
    background-color: #a1c862;
    color: white;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #5b9f56;
    font-weight: bold;
}


.containerCstor .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerCstor .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerCstor .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerCstor .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerCstor .logoNameBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10px;
}

.containerCstor .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerCstor .gcoin img {
    width: 100%;
    height: auto;
}

.containerCstor .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerCstor .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerCstor .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerCstor .gcoinlogox img {
    width: 100%;
    height: auto;
}



.containerCstor .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerCstor .buyComprar {
    background-color: #a1c862;
    color: black;
    margin-top: 44px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #5b9f56;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCstor .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerCstor .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCstor .mepagePage {
    display: flex;
    align-items: center;
    
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: 80px;
    justify-content: center;
    flex-direction: row;
    border-bottom: 3px solid white;
    /*min-height: 80vh;*/
    min-height: auto;
    width: 90%;

    border-radius: 10px;
    border-top-left-radius: 20px;
    background-color: #2c8a25;

    border-left: 10px solid #5b9f56;
    border-bottom: 10px solid #5b9f56;
}

.containerCstor .btcadress {
    
    margin-top: 20px;
    padding: 24px;
    border-radius: 3.3px;
    border: 7px solid #ebb837;
}
/*.paymentBitcoin {
    background-color: #dab66d;
}*/

.containerCstor .email {
    margin-top: 40px;

    font-size: 18.8px;
    font-family: Courier;
}

.containerCstor .payment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    font-size: 18.8px;
    font-family: Courier;

    margin-top: 30px;
}

.containerCstor .paymentBut { 
    background-color: #efcb70;
    color: white;
    
    
    
    text-align: center;
    font-size: 17.5px;


    
    width: 100%;
    min-width: 120px;
    /*padding: 10px;*/

    padding: 7px;

    /*border-radius: 4px;*/
    border-radius: 7px;


    cursor: pointer;

    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;

    font-weight: bold;
}

.containerTor .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerTor .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerTor .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerCstor .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerCstor .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: auto;
    width: 20%;
    margin-top: 24px;
    margin-left: 24px;
    border-radius: 3px;
    border: 3px solid white;
}

.containerCstor .secondsell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    width: 58%;
    margin-top: 24px;
    margin-left: 20px;
    border-radius: 3px;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.containerCstor .quantitydiv {
    display: flex;
flex-direction: column;
    
    
}

.containerCstor .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerCstor .gamelogo img {
    width: 100%;
    height: auto;
}

.containerCstor .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerCstor .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCstor .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCstor .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCstor .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCstor .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerCstorSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerCstor {
        display: none;
    }
    
    .containerCstorSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerCstorSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerCstorSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 3px;
    }
      
    .containerCstorSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
    }
      
    .containerCstorSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
    }
      
    .containerCstorSmall .gcoinlogox {
        width: 15px;
        height: 15px;
    }

    .containerCstorSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
      
    .containerCstorSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerCstorSmall .namelogo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.8px;
    }

    .containerCstorSmall .btcadress {
    font-family: Courier;
        margin-top: 20px;
        padding: 24px;
        border-radius: 3.3px;
        border: 7px solid #ebb837;
    }
    /*.paymentBitcoin {
        background-color: #dab66d;
    }*/

    .containerCstorSmall .buyComprar {
        background-color: #a1c862;
        color: black;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
        font-size: 17.5px;
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #5b9f56;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCstorSmall .email {
        margin-top: 40px;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
    }
    
    .containerCstorSmall .payment {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-weight: bold;
        font-size: 18.8px;
        font-family: Courier;
    
        margin-top: 30px;
    }
    
    .containerCstorSmall .paymentBut { 
        background-color: #efcb70;
        color: white;
        
        
        
        text-align: center;
        font-size: 17.5px;
    
    
        
        width: 100%;
        min-width: 120px;
        /*padding: 10px;*/
    
        padding: 7px;
    
        /*border-radius: 4px;*/
        border-radius: 7px;
    
    
        cursor: pointer;
    
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
    
        font-weight: bold;
    }
      
    .containerCstorSmall .gcoinlogox img {
        width: 100%;
        height: auto;
    }

    .containerCstorSmall .quantity {
        margin-top: 30px;
        font-family: Courier;
        font-size: 20px;
        font-weight: bold;
    }

    .containerCstorSmall .quantitydiv {
       display: flex;
       flex-direction: column;
    }

    .containerCstorSmall .logoutButton {
        background-color: #efcb70;
        color: #000;
        margin-left: 10px;
        text-align: center;
        font-size: 17.5px;
        width: 25%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerCstorSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerCstorSmall .simplelogo {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerCstorSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        padding-bottom: 4.4px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerCstorSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerCstorSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerCstorSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerCstorSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerCstorSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerCstorSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        width: 90%;
    }

    .containerCstorSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 40%;
    }
      
    .containerCstorSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }
    
    .containerCstorSmall .mepagePage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerCstorSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerCstorSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerCstorSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerCstorSmall .logoName {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerCstorSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        height: 37vh;
        width: 50%;
        margin-top: 24px;
        margin-left: 24px;
        border-radius: 3px;
        border: 3px solid white;
    }
    
    .containerCstorSmall .gamelogo {
        width: auto;
        height: auto;
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    
    .containerCstorSmall .gamelogo img {
        width: auto;
        height: 100%;
        object-fit: contain;
    }
    
    .containerCstorSmall .playButton {
        background-color: #a1c862;
        color: white;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCstorSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCstorSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCstorSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCstorSmall .joGameButtonLink {
        text-decoration: none;
    }
}
