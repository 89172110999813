.containerAtor {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerAtorSmall {
    display: none;
}

.containerAtor .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerAtor .buyComprar {
    background-color: #a1c862;
    color: white;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    font-size: 17.5px;
    
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAtor .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-left: 3px;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerAtor .playbuybutton {
    background-color: #a1c862;
    color: white;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
}

.containerAtor .logoNameBot {
    width: 80%;
    margin-left: 10px;
}

.containerAtor .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerAtor .gcoin img {
    width: 100%;
    height: auto;
}

.containerAtor .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerAtor .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerAtor .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAtor .mepagePage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerTor .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerAtor .bottestComprar {
    display: flex;
width: 100%;
    
    
}

.containerAtor .wicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    /*margin-left: 3px;*/
  
    height: auto;
  
    border: 0px solid black;
  }
  
  .containerAtor .wicon img {
    width: 100%;
    height: auto;
  }

.containerAtor .divprom {
    margin-left: 20px;
    width: 10.8%;
  }
  
  .containerAtor .prom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 28px;
    gap: 3.8px;
    margin-left: 20px;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 12vh;
    border-radius: 7px;
    margin-right: 3px;
    
    
    /**/
    background-color: #efde98;
    border: 0px solid #efcb70;
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;
    /*border: 7px solid #efcb70;*/
    width: 85%;
  }
  
  .containerAtor .promdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
    width: 90%;
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  }
  
  .containerAtor .promdivbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    font-size: 20px;
    /*border: 3px solid white;*/
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
  
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  
    
  }
  
  .containerAtor .promdivbotbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.containerTor .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerTor .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerAtor .aftercheckdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
background-color: #2c8a25;
}

.containerAtor .aftercheckediv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
background-color: #ebb837;
}

.containerAtor .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerAtor .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 37vh;
    width: 20%;
    margin-top: 24px;
    
    border-radius: 3px;
    border: 3px solid white;
}

.containerAtor .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerAtor .gamelogo img {
    width: 100%;
    height: auto;
}

.containerAtor .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerAtor .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAtor .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAtor .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerAtor .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerAtor .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerAtor .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerAtor .logoNameBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10px;
}

.containerAtor .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerAtor .gcoin img {
    width: 100%;
    height: auto;
}

.containerAtor .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerAtor .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerAtor .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerAtor .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerAtor .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAtor .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAtor .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerAtorSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerAtor {
        display: none;
    }
    
    .containerAtorSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerAtorSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerAtorSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 3px;
    }
      
    .containerAtorSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
    }
      
    .containerAtorSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
    }
      
    .containerAtorSmall .gcoinlogox {
        width: 15px;
        height: 15px;
    }

    .containerAtorSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
      
    .containerAtorSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerAtorSmall .aftercheckdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        padding: 10px;
    
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    background-color: #2c8a25;
    }

    .containerAtorSmall .bottestComprar {
display: flex;
width: 100%;
        margin-top: 10px;
        
    }

    .containerAtorSmall .aftercheckediv {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    background-color: #ebb837;
    }
    
    .containerAtorSmall .namelogo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.8px;
    }
      
    .containerAtorSmall .gcoinlogox img {
        width: 100%;
        height: auto;
    }

    .containerAtorSmall .logoutButton {
        background-color: #efcb70;
        color: #000;
        margin-left: 10px;
        text-align: center;
        font-size: 17.5px;
        width: 25%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerAtorSmall .wicon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        /*margin-left: 3px;*/
      
        height: auto;
      
        border: 0px solid black;
      }
      
      .containerAtorSmall .wicon img {
        width: 100%;
        height: auto;
      }
    
    .containerAtorSmall .divprom {
        margin-left: 20px;
        width: 10.8%;
      }
      
      .containerAtorSmall .prom {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 28px;
        gap: 3.8px;
        margin-left: 20px;
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        height: 12vh;
        border-radius: 7px;
        margin-right: 3px;
        
        
        /**/
        background-color: #efde98;
        border: 0px solid #efcb70;
        border-left: 7px solid #efcb70;
        border-bottom: 7px solid #efcb70;
        /*border: 7px solid #efcb70;*/
        width: 90%;
      }
      
      .containerAtorSmall .promdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 20px;
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
        width: 90%;
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      }
      
      .containerAtorSmall .promdivbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        font-size: 20px;
        /*border: 3px solid white;*/
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
      
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      
        
      }
      
      .containerAtorSmall .promdivbotbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }

    .containerAtorSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerAtorSmall .simplelogo {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerAtorSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        padding-bottom: 4.4px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerAtorSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerAtorSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerAtorSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerAtorSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerAtorSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerAtorSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 3px;
        width: 90%;
    }

    .containerAtorSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 40%;
    }
      
    .containerAtorSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }

    .containerAtorSmall .buyComprar {
        background-color: #a1c862;
        color: white;
        margin-top: 20px;
        text-align: center;
        text-decoration: none;
        font-size: 17.5px;
        
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAtorSmall .mepagePage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerAtorSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerAtorSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerAtorSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerAtorSmall .logoName {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerAtorSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        height: 37vh;
        width: 50%;
        margin-top: 24px;
        
        border-radius: 3px;
        border: 3px solid white;
    }
    
    .containerAtorSmall .gamelogo {
        width: auto;
        height: auto;
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    
    .containerAtorSmall .gamelogo img {
        width: auto;
        height: 100%;
        object-fit: contain;
    }
    
    .containerAtorSmall .playButton {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAtorSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAtorSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAtorSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAtorSmall .joGameButtonLink {
        text-decoration: none;
    }
}
