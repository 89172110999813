.containerSimple {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerSimpleSmall {
    display: none;
}

.containerSimple .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerSimple .gcoin img {
    width: 100%;
    height: auto;
}

.containerSimple .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerSimple .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerSimple .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerSimple .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerSimple .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerSimple .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerSimple .logoName {
    display: flex;
    flex-direction: row;
    width: 58%;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    margin-left: 10px;
}

.containerSimple .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 4.8px;

    padding-left: 4.8px;
    padding-right: 4.8px;

    width: auto;
    background-color: #da9f67;
  }

.containerSimple .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 37vh;
    width: 24%;
    margin-top: 24px;
    margin-left: 24px;
    border-radius: 3px;
    border: 3px solid white;
}

/*.containerSimple .gameplaydiv {
}*/

.containerSimple .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerSimple .gamelogo img {
    width: 100%;
    height: auto;
}

.containerSimple .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerSimple .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerSimple .playButtonPlinko {
    background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        /*cursor: pointer;*/
        cursor: not-allowed;
        /*pointer-events: none;*/
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        border-color: #ebb837;
}

/*.containerSimple .playButtonPlinko.disabled {
    background-color: #cccccc; 
    color: #666666; 
    cursor: not-allowed; 
    pointer-events: none; 
    border-color: #aaaaaa; 
}*/

.containerSimple .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerSimple .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerSimple .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerSimple .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerSimpleSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerSimple {
        display: none;
    }
    
    .containerSimpleSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerSimpleSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerSimpleSmall .simplelogo {
        font-family: Courier;
        margin-left: 3px;
    }
    
    .containerSimpleSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerSimpleSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
    
        padding-bottom: 4px;
    
        margin-top: 3px;
        /*border-bottom: 4.4px solid white;*/
    
        width: 90%;
      }

      .containerSimpleSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
    
        /*border: 1px solid;*/
    
        margin-left: 3px;
        width: 100%;
      }
      
      .containerSimpleSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
    
        padding-left: 4.8px;
    padding-right: 4.8px;
    
        width: auto;
        background-color: #da9f67;
      }
    
    .containerSimpleSmall .mepagePage {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;

        gap: 3px;
    }
    
    .containerSimpleSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerSimpleSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerSimpleSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerSimpleSmall .logoName {
        display: flex;
        flex-direction: row;
        width: 58%;

        margin-bottom: 4.1px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        margin-left: 10px;

        
    }
    
    .containerSimpleSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        margin-left: 10px;
        height: 37vh;
        width: 45%;
        margin-top: 24px;
        
        border-radius: 3px;

        border: 3px solid white;
    }
    
    /*.containerSimpleSmall .gameplaydiv {
    }*/
    
    .containerSimpleSmall .gamelogo {
        width: auto;
        height: auto;
        
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;

        /*border: 1px solid;*/
        /*what determines size*/

        
    }
    
    .containerSimpleSmall .gamelogo img {
        width: auto;
        height: 100%;
        

        object-fit: contain;
    }
    
    .containerSimpleSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 100%;
    }
    
    .containerSimpleSmall .playButton {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        border-color: #79b13d;
    }
    
    .containerSimpleSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerSimpleSmall .playButtonPlinko {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        /*cursor: pointer;*/
        cursor: not-allowed;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        border-color: #ebb837;
        
    }
    
    .containerSimpleSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerSimpleSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerSimpleSmall .joGameButtonLink {
        text-decoration: none;
    }
    

}