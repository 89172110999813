.containerBPage {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerBPageSmall {
    display: none;
}

.containerBPage .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerBPage .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }

    .containerBPage .gcoinlogoo {
        width: 15px;
        height: 15px;
        margin-left: 3px;
    }
      
    .containerBPage .gcoinlogoo img {
        width: 100%;
        height: auto;
    }
      
    .containerBPage .gcoinlogox {
        width: 15px;
        height: 15px;
        margin-right: 8px;
        margin-left: 3px;
    }
      
    .containerBPage .gcoinlogox img {
        width: 100%;
        height: auto;
    }

.containerBPage .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerBPage .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerBPage .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerBPage .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}


.containerBPage .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerBPage .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerBPage .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerBPage .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerBPage .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}


    .containerBPage .logoNameBot {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 80%;
        margin-left: 10px;
    }


.containerBPage .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerBPage .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 30%;
    font-size: 20.8px;
    font-family: monospace;
}

.containerBPage .eggdiv {
    border: 0px solid white;
    border-left: 8px solid #a1c862;
    border-bottom: 8px solid #a1c862;

    border-radius: 10px;
    /*background-color: #85b43b;*/
    /*background-color: #92bb5c;*/
    /*background-color: #8db060;*/
    /*background-color: #91b761;*/
    
    /*background-color: #87ae58;*/
    background-color: #88b057;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: auto;
    padding-bottom: 40px;
    font-family: monospace;
    margin-top: 20px;
}

.containerBPage .buycontentlist {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.containerBPage .buytitle {
    font-weight: bold;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    font-size: 24px;
}

.containerBPage .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerBPage .gcoin img {
    width: 100%;
    height: auto;
}

.containerBPage .buyinfo {
    font-family: monospace;
    font-weight: bold;
    border-radius: 3px;
    padding: 3px;
    margin-top: 33px;
    border-radius: 1.5px;
    border: 3px solid white;
}

.containerBPage .buycontent {
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    margin-top: 33px;
    font-weight: bold;
}

.containerBPage .wicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    /*margin-left: 3px;*/
  
    height: auto;
  
    border: 0px solid black;
  }
  
  .containerBPage .wicon img {
    width: 100%;
    height: auto;
  }

.containerBPage .divprom {
    margin-left: 20px;
    width: 10.8%;
  }
  
  .containerBPage .prom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 28px;
    gap: 3.8px;
    margin-left: 20px;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    border-radius: 7px;
    margin-right: 3px;
    
    
    /**/
    background-color: #efde98;
    border: 0px solid #efcb70;
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;
    /*border: 7px solid #efcb70;*/
    width: 90%;
  }
  
  .containerBPage .promdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
    width: 90%;
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  }
  
  .containerBPage .promdivbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    font-size: 20px;
    /*border: 3px solid white;*/
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
  
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  
    
  }
  
  .containerBPage .promdivbotbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.containerBPage .radiobutton input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    border: 2px solid #2c8a25;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
}

.containerBPage .radiobutton input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #21651c;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.containerBPage .inputbetComprar {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
    padding: 5px;
}

.containerBPage .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerBPage .buyComprar {
    background-color: #2c8a25;
    color: white;
    margin-top: 44px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #5b9f56;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerBPage .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerBPage .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerBPage .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerBPage .joGameButtonLink {
    text-decoration: none;
}


@media (max-width: 768px) { 

    .containerBPage {
        display: none;
    }
    
    .containerBPageSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    
    
    .containerBPageSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        padding-bottom: 4.4px;
        border-bottom: 3px solid white;
        width: 90%;
    }
    
    .containerBPageSmall .mepagePage {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerBPageSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerBPageSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerBPageSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerBPageSmall .logoName {
        display: flex;
        flex-direction: row;
    
        font-size: 17px;
    
        /*border: 3px solid;*/
    
        width: 100%;
    
        padding-bottom: 3px;
    
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }

    .containerBPageSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
    
        padding-left: 3px;
        padding-right: 3px;
    
        width: auto;
        background-color: #da9f67;
      }

      .containerBPageSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
      }
    
      .containerBPageSmall .twomono {
        margin-right: 3px;
        font-size: 23px;
        font-family: Courier, monospace;
      }
      
      .containerBPageSmall .cuentaPage {
        display: flex;
        justify-content: center;
    
    
        border: 1px solid;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerBPageSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
    
    
        width: 100%;
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerBPageSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
    
    
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerBPageSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
    
        width: 100%;
    
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }

      .containerBPageSmall .wicon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        /*margin-left: 3px;*/
      
        height: auto;
      
        border: 0px solid black;
      }
      
      .containerBPageSmall .wicon img {
        width: 100%;
        height: auto;
      }
    
    .containerBPageSmall .divprom {
        margin-left: 20px;
        width: 10.8%;
      }
      
      .containerBPageSmall .prom {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 28px;
        gap: 3.8px;
        
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
        border-radius: 7px;
        margin-right: 3px;
        
        
        /*Lot project has variables
        but REACT_APP so no
        Made server private
        Later server server
        /**/
        background-color: #efde98;
        border: 0px solid #efcb70;
        border-left: 7px solid #efcb70;
        border-bottom: 7px solid #efcb70;
        /*border: 7px solid #efcb70;*/
        width: 90%;
      }
      
      .containerBPageSmall .promdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 20px;
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
        width: 90%;
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      }
      
      .containerBPageSmall .promdivbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        font-size: 20px;
        /*border: 3px solid white;*/
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
      
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      
        
      }
      
      .containerBPageSmall .promdivbotbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
      
      .containerBPageSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        /*border-bottom: 4.4px solid white;*/
        width: 90%;
      }
    
    .containerBPageSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerBPageSmall .secondtopmenuBPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        font-family: Courier, monospace;
        padding-bottom: 4px;
        margin-top: 10px;
        border-bottom: 4.4px solid white;
        width: 90%;
    }
    
    .containerBPageSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 30%;
        font-size: 20.8px;
        font-family: monospace;
    }
    
    .containerBPageSmall .eggdiv {
        border: 0px solid white;
        border-left: 8px solid #a1c862;
        border-bottom: 8px solid #a1c862;
        border-radius: 10px;
        background-color: #88b057;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 90%;
        height: auto;
    padding-bottom: 40px;


        font-family: monospace;
        margin-top: 20px;
    }
    
    .containerBPageSmall .buycontentlist {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .containerBPageSmall .buytitle {
        font-weight: bold;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        font-size: 24px;
    }
    
    .containerBPageSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerBPageSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerBPageSmall .buyinfo {
        font-family: monospace;
        font-weight: bold;
        border-radius: 3px;
        padding: 3px;
        margin-top: 33px;
        border-radius: 1.5px;
        border: 3px solid white;
    }
    
    .containerBPageSmall .buycontent {
        font-family: 'Courier New', Courier, monospace;
        font-size: 18.8px;
        margin-top: 33px;
        font-weight: bold;
    }
    
    .containerBPageSmall .radiobutton input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        background-color: #f0f0f0;
        border: 2px solid #2c8a25;
        cursor: pointer;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
    }
    
    .containerBPageSmall .radiobutton input[type="radio"]:checked::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: #21651c;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .containerBPageSmall .inputbetComprar {
        display: inline-block;
        width: 100px;
        margin-left: 10px;
        padding: 5px;
    }
    
    .containerBPageSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerBPageSmall .buyComprar {
        background-color: #2c8a25;
        color: white;
        margin-top: 44px;
        text-align: center;
        font-size: 17.5px;
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #5b9f56;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerBPageSmall .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 25%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }
    
    .containerBPageSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerBPageSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 40%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerBPageSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 20%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerBPageSmall .joGameButtonLink {
        text-decoration: none;
    }
    
    .containerBPageSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
      }
      
      .containerBPageSmall .gcoin img {
        width: 100%;
        height: auto;
      }
    
      .containerBPageSmall .namelogo {
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        margin-left: 4.8px;
    
        /*border: 1px solid;*/
      }
    
      .containerBPageSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    
        gap: 3px;
    
      }
      
      .containerBPageSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
        
      }
      
      .containerBPageSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
      }
      
      .containerBPageSmall .gcoinlogox {
        width: 15px;
        height: 15px;
        
      }
      
      .containerBPageSmall .gcoinlogox img {
        width: 100%;
        height: auto;
      }

}