.containerCvende {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerCvendeSmall {
    display: none;
}

.containerCvende .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerCvende .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-left: 3px;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerCvende .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCvende .playbuybutton {
    background-color: #a1c862;
    color: white;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
}

.containerCvende .logoNameBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10px;
}

.containerCvende .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerCvende .gcoin img {
    width: 100%;
    height: auto;
}

.containerCvende .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerCvende .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}


.containerCvende .buyComprar {
    
        background-color: #a1c862;
        color: white;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: Courier;
    
}
.containerCvende .mepagePage {
    display: flex;
    align-items: center;
    
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: 80px;
    justify-content: center;
    flex-direction: row;
    border-bottom: 3px solid white;
    /*min-height: 80vh;*/
    min-height: auto;
    width: 90%;

    

    border-radius: 10px;
    border-top-left-radius: 20px;
    background-color: #2c8a25;

    border-left: 10px solid #5b9f56;
    border-bottom: 10px solid #5b9f56;
}

.containerCvende .qdiv {
display: flex;
flex-direction: column;
}

.containerCvende .email {
    margin-top: 8px;

    font-size: 18.8px;
    font-family: Courier;
}

.containerCvende .payment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    font-size: 18.8px;
    font-family: Courier;

    margin-top: 30px;
    margin-bottom: 10px;
}

.containerCvende .secondsell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 50vh;
    width: 58%;
    margin-top: 24px;
    margin-left: 20px;
    border-radius: 3px;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.containerTor .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerTor .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerTor .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerCvende .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerCvende .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerCvende .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerCvende .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerCvende .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerCvende .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerCvende .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerCvende .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerCvende .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerCvende .paymentBut { 
    background-color: #dab66d;
    color: white;
    
    
    
    text-align: center;
    font-size: 17.5px;


    
    width: 100%;
    min-width: 120px;
    /*padding: 10px;*/

    padding: 7px;

    /*border-radius: 4px;*/
    border-radius: 7px;


    cursor: pointer;

    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;

    font-weight: bold;
}

.containerCvende .btcadress {
    margin-top: 20px;

    margin-bottom: 20px;
    text-align: center;
    
    
}
.containerCvende .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 30vh;
    width: 20%;
    margin-top: 24px;
    margin-left: 24px;
    border-radius: 3px;
    border: 3px solid white;
}

/*.containerSimple .gameplaydiv {
}*/

.containerCvende .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerCvende .gamelogo img {
    width: 100%;
    height: auto;
}

.containerCvende .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerCvende .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCvende .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCvende .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCvende .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCvende .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerCvendeSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerCvende {
        display: none;
    }
    
    .containerCvendeSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerCvendeSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerCvendeSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 3px;
    }
      
    .containerCvendeSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
    }
      
    .containerCvendeSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
    }

    .containerCvendeSmall .email {
        margin-top: 8px;
    
        font-size: 18.8px;
        font-family: Courier;
    }
    
    .containerCvendeSmall .payment {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    
        font-size: 18.8px;
        font-family: Courier;
    
        margin-top: 30px;
        margin-bottom: 10px;
    }
      
    .containerCvendeSmall .gcoinlogox {
        width: 15px;
        height: 15px;
    }

    .containerCvendeSmall .buyComprar {
    
        background-color: #a1c862;
        color: white;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;

        font-family: Courier;
    
}

.containerCvendeSmall .secondsell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 40vh;
    width: 100%;
    margin-top: 10px;
    
    border-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #2c8a25;

    border-left: 10px solid #5b9f56;
    border-bottom: 10px solid #5b9f56;
}

    .containerCvendeSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
      
    .containerCvendeSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerCvendeSmall .namelogo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.8px;
    }
      
    .containerCvendeSmall .gcoinlogox img {
        width: 100%;
        height: auto;
    }

    .containerCvendeSmall .paymentBut { 
        background-color: #dab66d;
        color: white;
        
        
        
        text-align: center;
        font-size: 17.5px;
    
    
        
        width: 100%;
        min-width: 120px;
        /*padding: 10px;*/
    
        padding: 7px;
    
        /*border-radius: 4px;*/
        border-radius: 7px;
    
    
        cursor: pointer;
    
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
    
        font-weight: bold;
    }

    .containerCvendeSmall .logoutButton {
        background-color: #efcb70;
        color: #000;
        margin-left: 10px;
        text-align: center;
        font-size: 17.5px;
        width: 25%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerCvendeSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerCvendeSmall .simplelogo {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }

    .containerCvendeSmall .btcadress {
        margin-bottom: 20px;
    }
    
    .containerCvendeSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        padding-bottom: 4.4px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerCvendeSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerCvendeSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerCvendeSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerCvendeSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerCvendeSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerCvendeSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        width: 90%;
    }

    .containerCvendeSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 40%;
    }
      
    .containerCvendeSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }
    
    .containerCvendeSmall .mepagePage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;

        
    }

    .containerCvendeSmall .quantity {
        
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;

    }

    .containerCvendeSmall .qdiv {
        display: flex;
        flex-direction: column;
    }
    
    .containerCvendeSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerCvendeSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerCvendeSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerCvendeSmall .logoName {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerCvendeSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        height: 37vh;
        width: 50%;
        margin-top: 24px;
        margin-left: 24px;
        border-radius: 3px;
        border: 3px solid white;
    }
    
    .containerCvendeSmall .gamelogo {
        width: auto;
        height: auto;
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    
    .containerCvendeSmall .gamelogo img {
        width: auto;
        height: 100%;
        object-fit: contain;
    }
    
    .containerCvendeSmall .playButton {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCvendeSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCvendeSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCvendeSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCvendeSmall .joGameButtonLink {
        text-decoration: none;
    }
}
