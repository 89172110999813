/* Login.css */

body, html {
    margin: 0;
    padding: 0;
}

.containerLog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #282c34;
    width: 100vw;
}

.bomenubarLogin {
    margin: 0px 0;
    font-size: 24px;
    font-weight: bold;
    color: #1B509C;
    margin-bottom: 50px;
    margin-top: 0px;
    /*background-color: #fcb900;*/

    justify-content: center;
    align-items: center;
    display: flex;
    height: 4vw;
    width: 35%;
    /*border-radius: 3px;*/

    /*border-top: 3px solid #fcb900;
    border-bottom: 3px solid #fcb900;*/

    border-top: 3px solid #efcb70;
    border-bottom: 3px solid #efcb70;

    
}

.menubarLogin {
    font-size: 24px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 60px;*/
    margin-top: 0px;
    background-color: #efcb70;

    justify-content: center;
    align-items: center;
    display: flex;
    
    /*height: 4vw;
    width: 35%;*/

    height: 80%;
    width: 100%;

    /*border: 10px solid;*/

    font-family: monospace;
    border-radius: 1.5px;
}

.welcome {
    /* Welcome section styles */
    background-color: #ebd67f;
    padding: 10px;
    color: #282c34;
    margin-bottom: 50px;
    margin-top: 10px;
    border-radius: 1px;

    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 24px;

    border-bottom: 5px solid #f1ebd4;
    border-left: 5px solid #f1ebd4;
}

.welcomeInk {
    text-decoration: none;
    color: #000000;
}

.login-formLogin {
    /* Form container styles */
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 1.5px;
    width: 30%;
    margin-bottom: 70px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 15px;
    /*font-weight: bold;*/
    font-family: monospace;

    border-left: 4px solid #ebd67f;
    border-bottom: 4px solid #ebd67f;
    
}

.login-formLogin h2 {
    /* Form heading styles */
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.login-formLogin form {
    /* Form styles */
    display: flex;
    flex-direction: column;
    
}

.login-formLogin .mb-3 {
    /* Form group styles */
    margin-bottom: 15px;
}

.login-formLogin label {
    /* Label styles */
    font-weight: bold;
}

.login-formLogin input {
    /* Input styles */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-formLogin button {
    /* Button styles */
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-weight: bold;

    font-family: monospace;
}

.login-formLogin button.btn-default {
    /* Default button styles */
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
}

.login-formLogin button.btn-default.border {
    /* Border button styles */
    border-color: #dee2e6;
}

.createButton {
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
    text-decoration: none;
    text-align: center;
    font-size: 13.5px;
    padding: 10px;

    border-radius: 4px;
    cursor: pointer;

    font-weight: bold;
}

.createButton {
    border-color: #dee2e6;
}

.Admin {
    display: flex;
    justify-content: flex-end; /* Align content to the right horizontally */
    align-items: center;
    width: 30%;
    border: 1px solid;
}

/* Add any additional styling as needed */

@media (max-width: 768px) { 
  
    .containerLog .login-formLogin {
      width: 50%;
    }

    .containerLog .bomenubarLogin {
        width: 60%;
        height: 10%;
    }

    
    
    
    }