.containerPageAccount {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.containerPageAccountSmall {
  display: none;
}

.containerPageAccount .topmenuPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 17px;
  border-bottom: 3px solid white;
  width: 90%;
}

.containerPageAccount .mepagePage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 3px solid white;
  min-height: 80vh;
  width: 90%;
}

.containerPageAccount .gamecoin {
  font-weight: bold;
  margin-left: 4.8px;
  margin-right: 4.8px;
}

.containerPageAccount .mepagePageTitle {
  font-family: Courier, monospace;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 3px solid white;
  margin-left: 24px;
}

.containerPageAccount .gameMenuPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.containerPageAccount .gamePage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid white;
  border-radius: 10.1px;
  padding: 10px;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  width: 30%;
  height: 15vh;
}

.containerPageAccount .gcoinlogoo {
  width: 15px;
  height: 15px;
  margin-left: 3px;
}

.containerPageAccount .gcoinlogoo img {
  width: 100%;
  height: auto;
}

.containerPageAccount .gcoinlogox {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  margin-left: 3px;
}

.containerPageAccount .gcoinlogox img {
  width: 100%;
  height: auto;
}

.containerPageAccount .logoName {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  width: 100%;
  padding-bottom: 3px;
  font-weight: bold;
  font-family: Courier, monospace;
  margin-left: 10px;
}

.containerPageAccount .logoNameBot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 68%;
  margin-left: 10px;
  font-family: 'Courier New', Courier, monospace;
}

.containerPageAccount .gcoin {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-left: 3px;
  margin-right: 3px;
}

.containerPageAccount .gcoin img {
  width: 100%;
  height: auto;
}

.containerPageAccount .mono {
  margin-right: 3px;
  font-size: 23px;
  font-family: monospace;
}

.containerPageAccount .pmono {
  margin-right: 3px;
  font-size: 23px;
  font-family: Courier, monospace;
}

.containerPageAccount .cuentaPage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20.8px;
  font-family: monospace;
  margin-left: 3px;
}

.containerPageAccount .secondtopmenuPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-bottom: 4px;
  margin-top: 10px;
  /*border: 1px solid;*/
  border-bottom: 4.4px solid white;
  width: 90%;
}

.containerPageAccount .botsecondtopmenuPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-bottom: 4px;
  margin-top: 10px;
  border-bottom: 4.4px solid white;
  width: 90%;
}

.containerPageAccount .buybutton {
  background-color: #a1c862;
  color: #282c34;
  font-family: Courier, monospace;
  text-align: center;
  font-size: 17.5px;
  text-decoration: none;
  width: 28.8%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  margin-right: 3px;
  border-color: #79b13d;
}

.containerPageAccount .ellbutton {
  background-color: #efcb70;
  color: #282c34;
  font-family: Courier, monospace;
  text-align: center;
  font-size: 17.5px;
  text-decoration: none;
  width: 28.8%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  margin-right: 3px;
  border-color: #ebb837;
}

.containerPageAccount .joGameButton:disabled {
  cursor: not-allowed;
  background-color: #efcb70;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #ebb837;
}

.containerPageAccount .beta {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-left: 4.8px;

  padding-left: 4.8px;
  padding-right: 4.8px;

  width: auto;
  background-color: #da9f67;
}

.containerPageAccount .cuentaPageBalance {
  display: flex;
  justify-content: center;


  /*border: 1px solid;*/
  align-items: center;
  font-size: 20.8px;
  font-family: Courier, monospace;
  margin-left: 3px;
}

.containerPageAccount .joGameButton {
  background-color: #a1c862;
  color: #000;
  margin-top: 15px;
  text-align: center;
  font-size: 17.5px;
  width: 44%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #79b13d;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.containerPageAccount .logoutButton {
  background-color: #efcb70;
  color: #000;
  margin-left: 10px;
  text-align: center;
  font-size: 17.5px;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #ebb837;

  margin-bottom: 3px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.containerPageAccount .twomono {
  margin-right: 3px;
  font-size: 20.8px;
  font-family: Courier, monospace;
}

.containerPageAccount .joGameButtonLink {
  text-decoration: none;
}


@media (max-width: 768px) { 

  .containerPageAccount {
    display: none;
  }

  .containerPageAccountSmall {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .containerPageAccountSmall .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    padding-bottom: 4.4px;
    border-bottom: 3px solid white;
    width: 90%;
  }
  
  .containerPageAccountSmall .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
  }
  
  .containerPageAccountSmall .gamecoin {
    font-weight: bold;
    margin-left: 4.8px;
    margin-right: 4.8px;
  }
  
  .containerPageAccountSmall .mepagePageTitle {
    font-family: Courier, monospace;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 3px solid white;
    margin-left: 24px;
  }
  
  .containerPageAccountSmall .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }
  
  .containerPageAccountSmall .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
  }
  
  .containerPageAccountSmall .logoName {
    display: flex;
    flex-direction: row;

    font-size: 17px;

    /*border: 3px solid;*/

    width: 100%;

    padding-bottom: 3px;

    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
  }

  .containerPageAccountSmall .namelogo {

    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 4.8px;

    /*border: 1px solid;*/
  }

  .containerPageAccountSmall .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 4.8px;

    padding-left: 4.8px;
    padding-right: 4.8px;

    width: auto;
    background-color: #da9f67;
  }
  
  .containerPageAccountSmall .logoNameBot {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    margin-left: 3px;
    font-family: 'Courier New', Courier, monospace;

    /*border: 1px solid;*/
  }

  .containerPageAccountSmall .botlogoNameBot {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    margin-left: 3px;
    font-family: 'Courier New', Courier, monospace;

    /*border: 1px solid;*/
  }
  
  
  .containerPageAccountSmall .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
  }

  .containerPageAccountSmall .ellbutton {
    background-color: #efcb70;
    color: #282c34;
    font-family: Courier, monospace;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-right: 3px;
    border-color: #ebb837;
  }
  .containerPageAccountSmall .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
  }
  
  .containerPageAccountSmall .cuentaPage {
    display: flex;
    justify-content: center;


    border: 1px solid;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
  }

  .containerPageAccountSmall .buttonscuentaPage {
    display: flex;
    justify-content: center;


    width: 100%;
    /*border: 1px solid;*/
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
  }

  .containerPageAccountSmall .cuentaPageBalance {
    display: flex;
    justify-content: center;


    /*border: 1px solid;*/
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
  }

  .containerPageAccountSmall .cuentaPageName {
    display: flex;
    justify-content: flex-end;

    width: 100%;

    /*border: 1px solid;*/
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
  }
  
  .containerPageAccountSmall .secondtopmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-bottom: 4px;
    margin-top: 10px;
    /*border-bottom: 4.4px solid white;*/
    width: 90%;
  }
  
  .containerPageAccountSmall .botsecondtopmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
  }
  
  .containerPageAccountSmall .buybutton {
    background-color: #a1c862;
    color: #282c34;
    font-family: Courier, monospace;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-right: 3px;
    border-color: #79b13d;
  }
  
  .containerPageAccountSmall .joGameButton:disabled {
    cursor: not-allowed;
    background-color: #efcb70;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
  }

  .containerPageAccountSmall .pmono {
    margin-right: 3px;
    font-size: 23px;
    font-family: Courier, monospace;
  }
  
  .containerPageAccountSmall .joGameButton {
    background-color: #a1c862;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .containerPageAccountSmall .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 25%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .containerPageAccountSmall .joGameButtonLink {
    text-decoration: none;
  }

  .containerPageAccountSmall .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
  }
  
  .containerPageAccountSmall .gcoin img {
    width: 100%;
    height: auto;
  }

  .containerPageAccountSmall .namelogo {

    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 4.8px;

    /*border: 1px solid;*/
  }

  .containerPageAccountSmall .glogos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    /*border: 1px solid;*/

    gap: 3px;

  }
  
  .containerPageAccountSmall .gcoinlogoo {
    width: 15px;
    height: 15px;
    
  }
  
  .containerPageAccountSmall .gcoinlogoo img {
    width: 100%;
    height: auto;
  }
  
  .containerPageAccountSmall .gcoinlogox {
    width: 15px;
    height: 15px;
    
  }
  
  .containerPageAccountSmall .gcoinlogox img {
    width: 100%;
    height: auto;
  }
  

  

}


