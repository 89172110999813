.containerAfterCheckout {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerAfterCheckoutSmall {
    display: none;
}

.containerAfterCheckout .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerAfterCheckout .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAfterCheckout .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerAfterCheckout .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerAfterCheckout .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerAfterCheckout .aftercheck {
    text-align: center;
    margin-top: 20px;
    padding: 24px;
    border-radius: 3.3px;
    border: 7px solid #2c8a25;
}

.containerAfterCheckout .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerAfterCheckout .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerAfterCheckout .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerAfterCheckout .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerAfterCheckout .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerAfterCheckout .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}


    .containerAfterCheckout .logoNameBot {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 80%;
        margin-left: 10px;
    }

.containerAfterCheckout .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerAfterCheckout .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 30%;
    font-size: 20.8px;
    font-family: monospace;
}

.containerAfterCheckout .eggdiv {
    border: 0px solid white;
    border-left: 8px solid #a1c862;
    border-bottom: 8px solid #a1c862;
    background-color: #85b43b;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 78%;
    height: auto;
    font-family: monospace;
    margin-top: 20px;
}

.containerAfterCheckout .buycontentlist {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.containerAfterCheckout .buytitle {
    font-weight: bold;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    font-size: 24px;
}

.containerAfterCheckout .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerAfterCheckout .gcoin img {
    width: 100%;
    height: auto;
}

.containerAfterCheckout .buyinfo {
    font-family: monospace;
    font-weight: bold;
    border-radius: 3px;
    padding: 3px;
    margin-top: 33px;
    border-radius: 1.5px;
    border: 3px solid white;
}

.containerAfterCheckout .buycontent {
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    margin-top: 33px;
    font-weight: bold;
}

.containerAfterCheckout .radiobutton input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    border: 2px solid #2c8a25;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
}

.containerAfterCheckout .radiobutton input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #21651c;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.containerAfterCheckout .inputbetComprar {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
    padding: 5px;
}

.containerAfterCheckout .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerAfterCheckout .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerAfterCheckout .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerAfterCheckout .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerAfterCheckout .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerAfterCheckout .buyComprar {
    background-color: #2c8a25;
    color: white;
    margin-top: 44px;
    text-align: center;

    font-size: 17.5px;
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
   
    border-color: #5b9f56;

    text-decoration: none;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAfterCheckout .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAfterCheckout .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAfterCheckout .wicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    /*margin-left: 3px;*/
  
    height: auto;
  
    border: 0px solid black;
  }
  
  .containerAfterCheckout .wicon img {
    width: 100%;
    height: auto;
  }

.containerAfterCheckout .divprom {
    margin-left: 20px;
    width: 10.8%;
  }
  
  .containerAfterCheckout .prom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 28px;
    gap: 3.8px;
    margin-left: 20px;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    border-radius: 7px;
    margin-right: 3px;
    
    
    /**/
    background-color: #efde98;
    border: 0px solid #efcb70;
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;
    /*border: 7px solid #efcb70;*/
    width: 90%;
  }
  
  .containerAfterCheckout .promdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
    width: 90%;
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  }
  
  .containerAfterCheckout .promdivbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    font-size: 20px;
    /*border: 3px solid white;*/
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
  
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  
    
  }
  
  .containerAfterCheckout .promdivbotbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.containerAfterCheckout .bottestComprar {
    background-color: #2c8a25;
    color: white;
    margin-top: 44px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    min-width: 150px;
    padding: 10px;

    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #5b9f56;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAfterCheckout .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAfterCheckout .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 
    .containerAfterCheckout {
        display: none;
    }
    
    .containerAfterCheckoutSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerAfterCheckoutSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        padding-bottom: 4.4px;
        border-bottom: 3px solid white;
        width: 90%;
    }
    
    .containerAfterCheckoutSmall .mepagePage {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerAfterCheckoutSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }

    .containerAfterCheckoutSmall .aftercheck {
        text-align: center;
        margin-top: 20px;
        padding: 24px;
        border-radius: 3.3px;
        border: 7px solid #2c8a25;
    }
    
    .containerAfterCheckoutSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerAfterCheckoutSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerAfterCheckoutSmall .logoName {
        display: flex;
        flex-direction: row;
    
        font-size: 17px;
    
        /*border: 3px solid;*/
    
        width: 100%;
    
        padding-bottom: 3px;
    
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }

    .containerAfterCheckoutSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }

    .containerAfterCheckoutSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerAfterCheckoutSmall .twomono {
        margin-right: 3px;
        font-size: 23px;
        font-family: Courier, monospace;
    }
    
    .containerAfterCheckoutSmall .cuentaPage {
        display: flex;
        justify-content: center;
        border: 1px solid;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerAfterCheckoutSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerAfterCheckoutSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerAfterCheckoutSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerAfterCheckoutSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        width: 90%;
    }
    
    .containerAfterCheckoutSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAfterCheckoutSmall .secondtopmenuBPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        font-family: Courier, monospace;
        padding-bottom: 4px;
        margin-top: 10px;
        border-bottom: 4.4px solid white;
        width: 90%;
    }
    
    .containerAfterCheckoutSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 30%;
        font-size: 20.8px;
        font-family: monospace;
    }
    
    .containerAfterCheckoutSmall .eggdiv {
        border: 0px solid white;
        border-left: 8px solid #a1c862;
        border-bottom: 8px solid #a1c862;
        background-color: #85b43b;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 95%;
        height: auto;
        font-family: monospace;
        margin-top: 20px;
    }
    
    .containerAfterCheckoutSmall .buycontentlist {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .containerAfterCheckoutSmall .buytitle {
        font-weight: bold;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        font-size: 24px;
    }

    .containerAfterCheckoutSmall .wicon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        /*margin-left: 3px;*/
      
        height: auto;
      
        border: 0px solid black;
      }
      
      .containerAfterCheckoutSmall .wicon img {
        width: 100%;
        height: auto;
      }
    
    .containerAfterCheckoutSmall .divprom {
        margin-left: 20px;
        width: 10.8%;
      }
      
      .containerAfterCheckoutSmall .prom {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 28px;
        gap: 3.8px;
       
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
        border-radius: 7px;
        margin-right: 3px;
        
        
        /**/
        background-color: #efde98;
        border: 0px solid #efcb70;
        border-left: 7px solid #efcb70;
        border-bottom: 7px solid #efcb70;
        /*border: 7px solid #efcb70;*/
        width: 90%;
      }
      
      .containerAfterCheckoutSmall .promdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 20px;
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
        width: 90%;
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      }
      
      .containerAfterCheckoutSmall .promdivbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        font-size: 20px;
        /*border: 3px solid white;*/
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
      
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      
        
      }
      
      .containerAfterCheckoutSmall .promdivbotbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    
    .containerAfterCheckoutSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerAfterCheckoutSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerAfterCheckoutSmall .buyinfo {
        font-family: monospace;
        font-weight: bold;
        border-radius: 3px;
        padding: 3px;
        margin-top: 33px;
        border-radius: 1.5px;
        border: 3px solid white;
    }
    
    .containerAfterCheckoutSmall .buycontent {
        font-family: 'Courier New', Courier, monospace;
        font-size: 18.8px;
        margin-top: 33px;
        font-weight: bold;
    }
    
    .containerAfterCheckoutSmall .radiobutton input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        background-color: #f0f0f0;
        border: 2px solid #2c8a25;
        cursor: pointer;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
    }
    
    .containerAfterCheckoutSmall .radiobutton input[type="radio"]:checked::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: #21651c;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .containerAfterCheckoutSmall .inputbetComprar {
        display: inline-block;
        width: 100px;
        margin-left: 10px;
        padding: 5px;
    }
    
    .containerAfterCheckoutSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerAfterCheckoutSmall .buyComprar {
        background-color: #2c8a25;
        color: white;
        margin-top: 44px;
        text-align: center;
        font-size: 17.5px;
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        
        /*border-bottom: 3.5px solid;
        border-left: 3.5px solid;*/

        text-decoration: none;
        border-color: #5b9f56;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerAfterCheckoutSmall .bottestComprar {
        background-color: #2c8a25;
        color: white;
        margin-top: 44px;
        text-align: center;
        font-size: 17.5px;
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #5b9f56;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerAfterCheckoutSmall .logoutButton {
        background-color: #efcb70;
        color: #000;
        margin-left: 10px;
        text-align: center;
        font-size: 17.5px;
        width: 25%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAfterCheckoutSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAfterCheckoutSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 40%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAfterCheckoutSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 20%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAfterCheckoutSmall .joGameButtonLink {
        text-decoration: none;
    }
    
    .containerAfterCheckoutSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerAfterCheckoutSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerAfterCheckoutSmall .namelogo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.8px;
    }
    
    .containerAfterCheckoutSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 3px;
    }
    
    .containerAfterCheckoutSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
    }
    
    .containerAfterCheckoutSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
    }
    
    .containerAfterCheckoutSmall .gcoinlogox {
        width: 15px;
        height: 15px;
    }
    
    .containerAfterCheckoutSmall .gcoinlogox img {
        width: 100%;
        height: auto;
    }
}
