.containerHBuy {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerHBuySmall {
    display: none;
}

.containerHBuy .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerHBuy .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}



.containerHBuy .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerHBuy .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerHBuy .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerHBuy .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerHBuy .logoNameBot {
    display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 80%;
        margin-left: 10px;
}

.containerHBuy .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerHBuy .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 30%;
    font-size: 20.8px;
    font-family: monospace;
}

.containerHBuy .eggdiv {
    border: 0px solid white;
    border-left: 8px solid #a1c862;
    border-bottom: 8px solid #a1c862;
    background-color: #88b057;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 70vh;
    font-family: monospace;
    margin-top: 20px;

    overflow-x: auto;

    border-radius: 3px;
}

.containerHBuy .buycontentlist {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.containerHBuy .buytitle {
    font-weight: bold;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    font-size: 24px;
}

.containerHBuy .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerHBuy .gcoin img {
    width: 100%;
    height: auto;
}

.containerHBuy .buyinfo {
    font-family: monospace;
    font-weight: bold;
    border-radius: 3px;
    padding: 3px;
    margin-top: 33px;
    border-radius: 1.5px;
    border: 3px solid white;
}

.containerHBuy .buycontent {
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    margin-top: 33px;
    font-weight: bold;
}

.containerHBuy .radiobutton input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    border: 2px solid #2c8a25;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
}

.containerHBuy .radiobutton input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #21651c;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.containerHBuy .inputbetComprar {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
    padding: 5px;
}

.containerHBuy .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;
    font-weight: bold;
}

/* Styling the table container to prevent overflow */
.eggdiv {
    background-color: #88b057;
    padding: 20px;
    overflow-x: auto; /* Allow horizontal scrolling if content overflows */
    border-radius: 3px;
}

.eggdiv .h2 {
    font-size: 20px;
    font-weight: bold;
    font-family: monospace;
    background-color: #88b057;
    
    /* Allow horizontal scrolling if content overflows */
    
}

/* Styling the table */
.historyTable {
    width: 100%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0;
    font-family: Courier, monospace;
    font-size: 16px;
    color: white;
    background-color: #85b43b; /* Background color matching the container */
    border: 8px solid white; /* White borders for the table */
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Drop shadow for a retro look */
}

/* Styling the table headers */
.historyTable thead {
    background: #85b43b; /* Light gradient for the header */
    color: white; /* Dark text color for contrast */
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-bottom: 2px solid white; /* White bottom border */
}

.historyTable thead th {
    padding: 10px;
    text-align: left;
    border-right: 2px solid #85b43b; /* Border color matching the background */
    border-left: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff; /* Inner white border for a beveled look */
}

.historyTable thead th:first-child {
    border-left: none; /* Remove left border from the first header cell */
}

.historyTable thead th:last-child {
    border-right: none; /* Remove the right border from the last header cell */
}

/* Styling the table rows */
.historyTable tbody tr {
    background: #85b43b; /* Retro gradient for rows */
    border-bottom: 10px solid white; /* White border between rows */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.historyTable tbody tr:hover {
    background: linear-gradient(to bottom, #c8e69f, #b3d383); /* Slightly darker gradient on hover */
}

.historyTable tbody td {
    padding: 12px;
    color: white;
    font-family: monospace;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    border-right: 2px solid #85b43b; /* Border color matching the background */
    border-left: 4px solid #ffffff; /* Inner white border for a beveled look */
}

.historyTable tbody td:first-child {
    border-left: none; /* Remove left border from the first cell */
}

.historyTable tbody td:last-child {
    border-right: none; /* Remove the right border from the last cell */
}

/* Spacing and aligning the table cells */
.historyTable td, .historyTable th {
    padding: 12px 15px;
    text-align: left;
}

.containerHBuy .buyComprar {
    background-color: #a1c862;
    color: white;
    
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}
.containerHBuy .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerHBuy .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerHBuy .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerHBuy .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerHBuy .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerHBuy .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerHBuy .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerHBuy .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}
.containerHBuy .bottestComprar {
    display: flex;
    justify-content: center;
    align-items: center;
margin-left: 0px;
margin-right: 3px;

}

.containerHBuy .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}



.containerHBuy .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }



.containerHBuy .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerHBuy .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerHBuy .joGameButtonLink {
    text-decoration: none;
}


@media (max-width: 768px) { 

    .containerHBuy {
        display: none;
    }
    
    .containerHBuySmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    
    
    .containerHBuySmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        border-bottom: 3px solid white;
        width: 90%;
    }
    
    .containerHBuySmall .mepagePage {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerHBuySmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerHBuySmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerHBuySmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerHBuySmall .logoName {
        display: flex;
        flex-direction: row;
    
        font-size: 17px;
    
        /*border: 3px solid;*/
    
        width: 100%;
    
        padding-bottom: 3px;
    
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }

    .containerHBuySmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
    
        padding-left: 3px;
        padding-right: 3px;
    
        width: auto;
        background-color: #da9f67;
      }

      .containerHBuySmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
      }
    
      .containerHBuySmall .twomono {
        margin-right: 3px;
        font-size: 23px;
        font-family: Courier, monospace;
      }
      
      .containerHBuySmall .cuentaPage {
        display: flex;
        justify-content: center;
    
    
        border: 1px solid;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerHBuySmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
    
    
        width: 100%;
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerHBuySmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
    
    
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerHBuySmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
    
        width: 100%;
    
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
      
      .containerHBuySmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        /*border-bottom: 4.4px solid white;*/
        width: 90%;
      }
    
    .containerHBuySmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerHBuySmall .secondtopmenuBPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        font-family: Courier, monospace;
        padding-bottom: 4px;
        margin-top: 10px;
        border-bottom: 4.4px solid white;
        width: 90%;
    }
    
    .containerHBuySmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 30%;
        font-size: 20.8px;
        font-family: monospace;
    }
    
    .containerHBuySmall .eggdiv {
        border: 0px solid white;
        border-left: 8px solid #a1c862;
        border-bottom: 8px solid #a1c862;
        background-color: #88b057;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 95%;
        height: 70vh;

        border-radius: 3px;

        font-family: monospace;
        margin-top: 20px;
    }
    
    .containerHBuySmall .buycontentlist {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .containerHBuySmall .buytitle {
        font-weight: bold;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        font-size: 24px;
    }
    
    .containerHBuySmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerHBuySmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerHBuySmall .buyinfo {
        font-family: monospace;
        font-weight: bold;
        border-radius: 3px;
        padding: 3px;
        margin-top: 33px;
        border-radius: 1.5px;
        border: 3px solid white;
    }
    
    .containerHBuySmall .buycontent {
        font-family: 'Courier New', Courier, monospace;
        font-size: 18.8px;
        margin-top: 33px;
        font-weight: bold;
    }
    
    .containerHBuySmall .radiobutton input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        background-color: #f0f0f0;
        border: 2px solid #2c8a25;
        cursor: pointer;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
    }
    
    .containerHBuySmall .radiobutton input[type="radio"]:checked::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: #21651c;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .containerHBuySmall .inputbetComprar {
        display: inline-block;
        width: 100px;
        margin-left: 10px;
        padding: 5px;
    }
    
    .containerHBuySmall .buybutton {
        background-color: #a1c862;
        color: white;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
    }

    
    
    .containerHBuySmall .buyComprar {
        background-color: #a1c862;
        color: white;
        margin-top: 44px;
        text-align: center;
        font-size: 17.5px;
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerHBuySmall .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 25%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }
    
    .containerHBuySmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerHBuySmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 40%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerHBuySmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 20%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerHBuySmall .joGameButtonLink {
        text-decoration: none;
    }

    .historyTable {
        font-size: 14px;
    }

    .historyTable thead th {
        padding: 8px;
    }

    .historyTable tbody td {
        padding: 8px;
    }
    
    .containerHBuySmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
      }
      
      .containerHBuySmall .gcoin img {
        width: 100%;
        height: auto;
      }
    
      .containerHBuySmall .namelogo {
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        margin-left: 4.8px;
    
        /*border: 1px solid;*/
      }
    
      .containerHBuySmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    
        gap: 3px;
    
      }
      
      .containerHBuySmall .gcoinlogoo {
        width: 15px;
        height: 15px;
        
      }
      
      .containerHBuySmall .gcoinlogoo img {
        width: 100%;
        height: auto;
      }
      
      .containerHBuySmall .gcoinlogox {
        width: 15px;
        height: 15px;
        
      }
      
      .containerHBuySmall .gcoinlogox img {
        width: 100%;
        height: auto;
      }

}
