.containerTor {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerTorSmall {
    display: none;
}

.containerTor .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerTor .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-left: 3px;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerTor .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }

    .containerTor .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerTor .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerTor .twomono {
        margin-right: 3px;
        font-size: 20.8px;
        font-family: Courier, monospace;
    }

.containerTor .playbuybutton {
    background-color: #a1c862;
    color: black;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
}

.containerTor .logoNameBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10px;
}

.containerTor .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerTor .gcoin img {
    width: 100%;
    height: auto;
}

.containerTor .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerTor .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerTor .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerTor .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerTor .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerTor .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerTor .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerTor .wicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    /*margin-left: 3px;*/
  
    height: auto;
  
    border: 0px solid black;
  }
  
  .containerTor .wicon img {
    width: 100%;
    height: auto;
  }

.containerTor .divprom {
    margin-left: 20px;
    width: 10.8%;
  }
  
  .containerTor .prom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 28px;
    gap: 3.8px;
    margin-left: 20px;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 12vh;
    border-radius: 7px;
    margin-right: 3px;
    
    
    /**/
    background-color: #efde98;
    border: 0px solid #efcb70;
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;
    /*border: 7px solid #efcb70;*/
    width: 70%;
  }
  
  .containerTor .promdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
    width: 90%;
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  }
  
  .containerTor .promdivbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    font-size: 20px;
    /*border: 3px solid white;*/
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
  
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  
    
  }
  
  .containerTor .promdivbotbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.containerTor .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    padding-bottom: 20px;
    height: auto;
    
    width: 24%;
    margin-top: 24px;
    margin-left: 24px;
    border-radius: 3px;
    border: 3px solid white;
}

/*.containerSimple .gameplaydiv {
}*/

.containerTor .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerTor .gamelogo img {
    width: 100%;
    height: auto;
}

.containerTor .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerTor .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerTor .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerTor .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerTor .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerTor .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerTor .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerTor .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerTor .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerTor .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerTor .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerTorSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerTor {
        display: none;
    }
    
    .containerTorSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerTorSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerTorSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    
        gap: 3px;
    
      }
      
      .containerTorSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
        
      }
      
      .containerTorSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
      }
      
      .containerTorSmall .gcoinlogox {
        width: 15px;
        height: 15px;
        
      }

      .containerTorSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
      }
      
      .containerTorSmall .gcoin img {
        width: 100%;
        height: auto;
      }
    
      .containerTorSmall .namelogo {
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        margin-left: 4.8px;
    
        /*border: 1px solid;*/
      }
      
      .containerTorSmall .gcoinlogox img {
        width: 100%;
        height: auto;
      }


      .containerTorSmall .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 25%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }

      .containerTorSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerTorSmall .simplelogo {
        display: flex;
    flex-direction: row;

    font-size: 17px;

    /*border: 3px solid;*/

    width: 100%;

    padding-bottom: 3px;

    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
    }
    
    .containerTorSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerTorSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
    
    
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }

      .containerTorSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
      }

      .containerTorSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
    
    
        width: 100%;
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }
    
      .containerTorSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
    
        width: 100%;
    
        /*border: 1px solid;*/
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
      }

      .containerTorSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerTorSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
    
        padding-bottom: 4px;
    
        margin-top: 10px;
        /*border-bottom: 4.4px solid white;*/
    
        width: 90%;
      }

      .containerTorSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 40%;
      }
      
      .containerTorSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
    
        padding-left: 4.8px;
        padding-right: 4.8px;
    
        width: auto;
        background-color: #da9f67;
      }

      .containerTorSmall .wicon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        /*margin-left: 3px;*/
      
        height: auto;
      
        border: 0px solid black;
      }
      
      .containerTorSmall .wicon img {
        width: 100%;
        height: auto;
      }
    
    .containerTorSmall .divprom {
        margin-left: 20px;
        width: 10.8%;
      }
      
      .containerTorSmall .prom {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 28px;
        gap: 3.8px;
        margin-left: 20px;
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        height: 12vh;
        border-radius: 7px;
        margin-right: 3px;
        
        height: 50%;
        /**/
        background-color: #efde98;
        border: 0px solid #efcb70;
        border-left: 7px solid #efcb70;
        border-bottom: 7px solid #efcb70;
        /*border: 7px solid #efcb70;*/
        width: 90%;
      }
      
      .containerTorSmall .promdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 20px;
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
        width: 90%;
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      }
      
      .containerTorSmall .promdivbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        font-size: 20px;
        /*border: 3px solid white;*/
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
      
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      
        
      }
      
      .containerTorSmall .promdivbotbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    
    .containerTorSmall .mepagePage {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerTorSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerTorSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerTorSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerTorSmall .logoName {
        display: flex;
    flex-direction: row;

    font-size: 17px;

    /*border: 3px solid;*/

    width: 100%;

    padding-bottom: 4.4px;

    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
    }
    
    .containerTorSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        height: 37vh;
        width: 50%;
        margin-top: 24px;
        margin-left: 24px;
        border-radius: 3px;
        border: 3px solid white;
    }
    
    /*.containerSimpleSmall .gameplaydiv {
    }*/
    
    .containerTorSmall .gamelogo {
        width: auto;
        height: auto;
        
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;

        /*border: 1px solid;*/
        /*what determines size*/

        
    }
    
    .containerTorSmall .gamelogo img {
        width: auto;
        height: 100%;
        

        object-fit: contain;
    }
    
    
    
    .containerTorSmall .playButton {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerTorSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerTorSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerTorSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerTorSmall .joGameButtonLink {
        text-decoration: none;
    }
    

}