.App {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.button {

    margin-top: 24px;
    margin-bottom: 24px;

    padding: 24px;

    width: 24%;

    font-weight: bold;

    border: 3px solid white;
}

.buttonLink {
    text-decoration: none;

    color: white;
}

.App-header {
    margin-bottom: 0px;

    background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

    /*border: 1px solid white;*/
}