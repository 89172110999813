.containerPage {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.containerPageSmall {
  display: none;
}

.containerPage .topmenuPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 17px;
  border-bottom: 3px solid white;
  width: 90%;
}

.containerPage .mepagePage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 3px solid white;
  min-height: 80vh;
  width: 90%;
}

.containerPage .gamecoin {
  font-weight: bold;
  margin-left: 4.8px;
  margin-right: 4.8px;
}

.containerPage .mepagePageTitle {
  font-family: Courier, monospace;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 3px solid white;
  margin-left: 24px;
}

.containerPage .gameMenuPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.containerPage .gamePage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid white;
  border-radius: 10.1px;
  padding: 10px;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  width: 30%;
  height: 15vh;
}

.containerPage .logoName {
  display: flex;
  flex-direction: row;
  width: 58%;
  font-weight: bold;
  font-size: 24px;
  font-family: Courier, monospace;
  margin-left: 10px;
}

.containerPage .cuentaPage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  width: 40%;
}

.containerPage .beta {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-left: 10px;

  padding-left: 3px;
  padding-right: 3px;
  width: 15%;
  background-color: #da9f67;
}

.containerPage .toprow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.containerPage .divprom {
  margin-left: 20px;
  width: 10.8%;
}

.containerPage .prom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 3.8px;
  margin-left: 20px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  height: 12vh;
  border-radius: 7px;
  margin-right: 3px;
  
  
  /**/
  background-color: #efde98;
  border: 0px solid #efcb70;
  border-left: 7px solid #efcb70;
  border-bottom: 7px solid #efcb70;
  /*border: 7px solid #efcb70;*/
  width: auto;
}

.containerPage .promdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  border: 3px solid #efcb70;
  height: 75%;
  padding: 8px;

  font-weight: bold;
  font-family: Courier;
  background-color: #282c34;
}

.containerPage .promdivbot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  font-size: 20px;
  /*border: 3px solid white;*/
  border: 3px solid #efcb70;
  height: 75%;
  padding: 8px;

  font-weight: bold;
  font-family: Courier;
  background-color: #282c34;

  
}

.containerPage .promdivbotbot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.containerPage .joGameButton {
  background-color: #efcb70;
  color: #000;
  margin-top: 15px;
  text-align: center;
  font-size: 17.5px;
  width: 44%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #ebb837;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.containerPage .createButtonPage {
  background-color: #efcb70;
  color: #000;
  text-align: center;
  font-size: 17.5px;
  height: 100%;
  width: 40%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #ebb837;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.containerPage .loginButtonPage {
  background-color: #a1c862;
  color: #000;
  text-align: center;
  font-size: 17.5px;
  margin-left: 3px;
  height: 100%;
  width: 20%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #79b13d;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.containerPage .joGameButtonLink {
  text-decoration: none;
}

.containerPage .gcoin {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-left: 3px;
  margin-right: 3px;
}

.containerPage .gcoin img {
  width: 100%;
  height: auto;
}

.containerPage .gcoinlogoo {
  width: 15px;
  height: 15px;
  margin-left: 3px;
}

.containerPage .wicon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  /*margin-left: 3px;*/

  height: auto;

  border: 0px solid black;
}

.containerPage .wicon img {
  width: 100%;
  height: auto;
}

.containerPage .gcoinlogoo img {
  width: 100%;
  height: auto;
}

.containerPage .gcoinlogox {
  width: 15px;
  height: 15px;
  margin-right: 8px;

  margin-left: 3px;
}

.containerPage .gcoinlogox img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .containerPage {
    display: none;
  }
  .containerPageSmall {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .containerPageSmall .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
  }
  
  .containerPageSmall .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
  }
  
  .containerPageSmall .gamecoin {
    font-weight: bold;
    margin-left: 4.8px;
    margin-right: 4.8px;
  }
  
  .containerPageSmall .mepagePageTitle {
    font-family: Courier, monospace;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 3px solid white;
    margin-left: 24px;
  }
  
  .containerPageSmall .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }
  
  .containerPageSmall .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
  }
  
  .containerPageSmall .logoName {
    display: flex;
    flex-direction: row;

    font-size: 17px;

    /*border: 3px solid;*/

    width: 100%;

    padding-bottom: 4.4px;

    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
  }

  
  
  .containerPageSmall .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;

    /*border: 1px solid;*/

    margin-left: 3px;
    width: 100%;
  }
  
  .containerPageSmall .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 4.8px;

    padding-left: 3px;
    padding-right: 3px;

    width: auto;
    background-color: #da9f67;
  }

  .containerPageSmall .secondtopmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    padding-bottom: 4px;

    margin-top: 3px;
    /*border-bottom: 4.4px solid white;*/

    width: 90%;
  }
  
  .containerPageSmall .toprow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  
  .containerPageSmall .divprom {
    margin-left: 20px;
    width: 10.8%;
  }

  .containerPageSmall .wicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    /*margin-left: 3px;*/
    margin-right: 20px;
    height: auto;
  
    border: 0px solid black;
  }
  
  .containerPageSmall .wicon img {
    width: 100%;
    height: auto;
  }
  
  .containerPageSmall .prom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3.8px;
   
    margin-top: 10px;

    /*margin-left: 3px;*/
    
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 12vh;

    /*border-radius: 7px;*/
    border-radius: 4px;

    background-color: #efde98;


    border: 0px solid #efcb70;

    
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;


    width: 90%;
  }
  
  .containerPageSmall .promdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-family: Courier;
    font-weight: bold;
    font-size: 17px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;

    background-color: #282c34;
  }
  
  .containerPageSmall .promdivbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    /*margin-right: 50px;*/
    font-family: Courier;
    font-weight: bold;
    font-size: 17px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;

    background-color: #282c34;
  }
  
  .containerPageSmall .promdivbotbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .containerPageSmall .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .containerPageSmall .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    /*font-size: 17.5px;*/

    font-size: 16.7px;

    height: 100%;
    width: 38%;
    /*padding: 10px;*/
    padding: 4px;

    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .containerPageSmall .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 16.7px;
    margin-left: 3px;
    height: 100%;
    width: 35%;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .containerPageSmall .joGameButtonLink {
    text-decoration: none;
  }
  
  .containerPageSmall .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
  }
  
  .containerPageSmall .gcoin img {
    width: 100%;
    height: auto;
  }

  .containerPageSmall .namelogo {

    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-left: 4.8px;

    /*border: 1px solid;*/
  }

  .containerPageSmall .glogos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 3px;

  }
  
  .containerPageSmall .gcoinlogoo {
    width: 15px;
    height: 15px;
    
  }
  
  .containerPageSmall .gcoinlogoo img {
    width: 100%;
    height: auto;
  }
  
  .containerPageSmall .gcoinlogox {
    width: 15px;
    height: 15px;
    
  }
  
  .containerPageSmall .gcoinlogox img {
    width: 100%;
    height: auto;
  }
  
}
