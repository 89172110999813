.containerCheckout {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerCheckoutSmall {
    display: none;
}

.containerCheckout .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerCheckout .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCheckout .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}



.containerCheckout .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerCheckout .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerCheckout .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerCheckout .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerCheckout .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerCheckout .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerCheckout .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerCheckout .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerCheckout .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerCheckout .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerCheckout .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerCheckout .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}
    .containerCheckout .logoNameBot {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 80%;
        margin-left: 10px;
    }

.containerCheckout .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerCheckout .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 30%;
    font-size: 20.8px;
    font-family: monospace;
}

.containerCheckout .eggdiv {
    border: 0px solid white;
    border-left: 8px solid #a1c862;
    border-bottom: 8px solid #a1c862;
    border-radius: 10px;
    background-color: #88b057;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: auto;
    font-family: monospace;
    margin-top: 20px;
}

.containerCheckout .checkout {
    text-align: center;
}

.containerCheckout .total {
    text-align: center;
}

.containerCheckout .buycontentlist {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.containerCheckout .buytitle {
    font-weight: bold;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    font-size: 24px;
}

.containerCheckout .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerCheckout .gcoin img {
    width: 100%;
    height: auto;
}

.containerCheckout .buyinfo {
    font-family: monospace;
    font-weight: bold;
    border-radius: 3px;
    padding: 3px;
    margin-top: 33px;
    border-radius: 1.5px;
    border: 3px solid white;
}

.containerCheckout .buycontent {
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    margin-top: 33px;
    font-weight: bold;
}

.containerCheckout .radiobutton input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    border: 2px solid #2c8a25;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
}

.containerCheckout .radiobutton input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #21651c;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.containerCheckout .inputbetComprar {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
    padding: 5px;
}

.containerCheckout .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerCheckout .buyComprar {
    background-color: #2c8a25;
    color: white;
    margin-top: 44px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #5b9f56;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCheckout .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCheckout .btcadress {
    
    margin-top: 20px;
    padding: 24px;
    border-radius: 3.3px;
    border: 7px solid #2c8a25;
}
/*.paymentBitcoin {
    background-color: #dab66d;
}*/

.containerCheckout .email {
    margin-top: 40px;
    font-weight: bold;
    font-size: 18.8px;
    font-family: Courier;
}

.payment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: bold;
    font-size: 18.8px;
    font-family: Courier;

    margin-top: 30px;
}

.containerCheckout .paymentBut { 
    background-color: #dab66d;
    color: white;
    
    
    
    text-align: center;
    font-size: 17.5px;


    
    width: 100%;
    min-width: 120px;
    /*padding: 10px;*/

    padding: 7px;

    /*border-radius: 4px;*/
    border-radius: 7px;


    cursor: pointer;

    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;

    font-weight: bold;
}

.containerCheckout .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCheckout .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerCheckout .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 
    .containerCheckout {
        display: none;
    }
    
    .containerCheckoutSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerCheckoutSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        padding-bottom: 4.4px;
        border-bottom: 3px solid white;
        width: 90%;
    }
    
    .containerCheckoutSmall .mepagePage {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerCheckoutSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerCheckoutSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerCheckoutSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerCheckoutSmall .logoName {
        display: flex;
    flex-direction: row;

    font-size: 17px;

    /*border: 3px solid;*/

    width: 100%;

    padding-bottom: 3px;

    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
    }

    .containerCheckoutSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }

    .containerCheckoutSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerCheckoutSmall .twomono {
        margin-right: 3px;
        font-size: 23px;
        font-family: Courier, monospace;
    }
    
    .containerCheckoutSmall .cuentaPage {
        display: flex;
        justify-content: center;
        border: 1px solid;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerCheckoutSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerCheckoutSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerCheckoutSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerCheckoutSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        width: 90%;
    }
    
    .containerCheckoutSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCheckoutSmall .secondtopmenuBPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        font-family: Courier, monospace;
        padding-bottom: 4px;
        margin-top: 10px;
        border-bottom: 4.4px solid white;
        width: 90%;
    }
    
    .containerCheckoutSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 30%;
        font-size: 20.8px;
        font-family: monospace;
    }
    
    .containerCheckoutSmall .eggdiv {
        border: 0px solid white;
        border-left: 8px solid #a1c862;
        border-bottom: 8px solid #a1c862;
        
        background-color: #88b057;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 95%;
        height: auto;
        font-family: monospace;
        margin-top: 20px;
    }

    .containerCheckoutSmall .checkout {
        text-align: center;
    }

    .containerCheckoutSmall .total {
        text-align: center;
    }
    
    .containerCheckoutSmall .buycontentlist {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .containerCheckoutSmall .buytitle {
        font-weight: bold;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        font-size: 24px;
    }
    
    .containerCheckoutSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerCheckoutSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerCheckoutSmall .buyinfo {
        font-family: monospace;
        font-weight: bold;
        border-radius: 3px;
        padding: 3px;
        margin-top: 33px;
        border-radius: 1.5px;
        border: 3px solid white;
    }
    
    .containerCheckoutSmall .buycontent {
        font-family: 'Courier New', Courier, monospace;
        font-size: 18.8px;
        margin-top: 33px;
        font-weight: bold;
    }
    
    .containerCheckoutSmall .radiobutton input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        background-color: #f0f0f0;
        border: 2px solid #2c8a25;
        cursor: pointer;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
    }
    
    .containerCheckoutSmall .radiobutton input[type="radio"]:checked::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: #21651c;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .containerCheckoutSmall .inputbetComprar {
        display: inline-block;
        width: 100px;
        margin-left: 10px;
        padding: 5px;
    }
    
    .containerCheckoutSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerCheckoutSmall .buyComprar {
        background-color: #2c8a25;
        color: white;
        margin-top: 44px;
        text-align: center;
        font-size: 17.5px;
        width: 100%;
        min-width: 150px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #5b9f56;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerCheckoutSmall .logoutButton {
        background-color: #efcb70;
        color: #000;
        margin-left: 10px;
        text-align: center;
        font-size: 17.5px;
        width: 25%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCheckoutSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCheckoutSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 40%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCheckoutSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 20%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerCheckoutSmall .joGameButtonLink {
        text-decoration: none;
    }
    
    .containerCheckoutSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerCheckoutSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerCheckoutSmall .namelogo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.8px;
    }
    
    .containerCheckoutSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 3px;
    }
    
    .containerCheckoutSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
    }
    
    .containerCheckoutSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
    }
    
    .containerCheckoutSmall .gcoinlogox {
        width: 15px;
        height: 15px;
    }
    
    .containerCheckoutSmall .gcoinlogox img {
        width: 100%;
        height: auto;
    }

    .paymentpng {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
    
        margin-left: 3px;
    
        margin-right: 3px;
    }
    
    
    .paymentpng img {
        width: 100%;
        height: auto;
    }
    
    .payment {
        display: flex;
        flex-direction: row;
    
        margin-top: 30px;
        font-family: Courier;
    }
    
    
    
    
    .containerCheckoutSmall .btcadress {
    
        margin-top: 20px;
        padding: 24px;
        border-radius: 3.3px;
        border: 7px solid #2c8a25;
    }
    /*.paymentBitcoin {
        background-color: #dab66d;
    }*/
    
    .containerCheckoutSmall .email {
        margin-top: 40px;
        font-weight: bold;
        font-size: 18.8px;
    font-family: Courier;
    }
    
    .containerCheckoutSmall .paymentBut { 
        background-color: #dab66d;
        color: white;
        
        
        
        text-align: center;
        font-size: 17.5px;
    
    
        
        width: 100%;
        min-width: 120px;
        /*padding: 10px;*/
    
        padding: 7px;
    
        /*border-radius: 4px;*/
        border-radius: 7px;
    
    
        cursor: pointer;
    
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
    
        font-weight: bold;
    }

}

