.containerAvende {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerAvendeSmall {
    display: none;
}

.containerAvende .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerAvende .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-left: 3px;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerAvende .playbuybutton {
    background-color: #2c8a25;
    color: white;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #5b9f56;
    font-weight: bold;
}

.containerAvende .logoNameBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10px;
}

.containerAvende .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerAvende .gcoin img {
    width: 100%;
    height: auto;
}

.containerAvende .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerAvende .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerAvende .mepagePage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerAvende .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerAvende .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerAvende .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerAvende .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerAvende .wicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    /*margin-left: 3px;*/
  
    height: auto;
  
    border: 0px solid black;
  }
  
  .containerAvende .wicon img {
    width: 100%;
    height: auto;
  }

.containerAvende .divprom {
    margin-left: 20px;
    width: 10.8%;
  }
  
  .containerAvende .prom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 28px;
    gap: 3.8px;
    margin-left: 20px;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    border-radius: 7px;
    margin-right: 3px;
    
    
    /**/
    background-color: #efde98;
    border: 0px solid #efcb70;
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;
    /*border: 7px solid #efcb70;*/
    width: 90%;
  }
  
  .containerAvende .promdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
    width: 90%;
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  }
  
  .containerAvende .promdivbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    font-size: 20px;
    /*border: 3px solid white;*/
    border: 3px solid #efcb70;
    height: 75%;
    padding: 8px;
  
    font-weight: bold;
    font-family: Courier;
    background-color: #282c34;
  
    
  }
  
  .containerAvende .promdivbotbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.containerAvende .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 37vh;
    width: 20%;
    margin-top: 24px;
    
    border-radius: 3px;
    border: 3px solid white;
}

.containerAvende .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}

.containerAvende .gcoinlogoo img {
    width: 100%;
    height: auto;
}

.containerAvende .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}

.containerAvende .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerAvende .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerAvende .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerAvende .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerAvende .gamelogo img {
    width: 100%;
    height: auto;
}

.containerAvende .aftercheckdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
background-color: #2c8a25;
}

.containerAvende .bottestComprar {
    display: flex;
    width: 100%;
    margin-top: 10px;
    
}

.containerAvende .buyComprar {
background-color: #a1c862;
color: white;
margin-top: 20px;
text-align: center;
text-decoration: none;
font-size: 17.5px;

width: 100%;
min-width: 150px;
padding: 10px;
border-radius: 4px;
cursor: pointer;
border-top: 0px;
border-right: 0px;
border-bottom: 3.5px solid;
border-left: 3.5px solid;
border-color: #79b13d;
font-weight: bold;
font-family: 'Courier New', Courier, monospace;

}

.containerAvende .aftercheckediv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
background-color: #ebb837;
}

.containerAvende .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerAvende .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerAvende .twomono {
margin-right: 3px;
font-size: 20.8px;
font-family: Courier, monospace;
}

.containerAvende .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAvende .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAvende .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAvende .logoutButton {
    background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAvende .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerAvende .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerAvendeSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerAvende {
        display: none;
    }
    
    .containerAvendeSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerAvendeSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerAvendeSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 3px;
    }
      
    .containerAvendeSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
    }
      
    .containerAvendeSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
    }
      
    .containerAvendeSmall .gcoinlogox {
        width: 15px;
        height: 15px;
    }

    .containerAvendeSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
      
    .containerAvendeSmall .gcoin img {
        width: 100%;
        height: auto;
    }
    
    .containerAvendeSmall .namelogo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.8px;
    }
      
    .containerAvendeSmall .gcoinlogox img {
        width: 100%;
        height: auto;
    }

    .containerAvendeSmall .logoutButton {
        background-color: #efcb70;
        color: #000;
        margin-left: 10px;
        text-align: center;
        font-size: 17.5px;
        width: 25%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerAvendeSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerAvendeSmall .simplelogo {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerAvendeSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerAvendeSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerAvendeSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    

    .containerAvendeSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }
    
    .containerAvendeSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerAvendeSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerAvendeSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 10px;
        width: 90%;
    }

    .containerAvendeSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 40%;
    }
      
    .containerAvendeSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }
    
    .containerAvendeSmall .mepagePage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        min-height: 80vh;
        width: 90%;
    }
    
    .containerAvendeSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }
    
    .containerAvendeSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    
    .containerAvendeSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }
    
    .containerAvendeSmall .logoName {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerAvendeSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        height: 37vh;
        width: 50%;
        margin-top: 24px;
        
        border-radius: 3px;
        border: 3px solid white;
    }
    
    .containerAvendeSmall .gamelogo {
        width: auto;
        height: auto;
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    
    .containerAvendeSmall .gamelogo img {
        width: auto;
        height: 100%;
        object-fit: contain;
    }

    .containerAvendeSmall .aftercheckdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    background-color: #2c8a25;
    }
    
    .containerAvendeSmall .bottestComprar {
        display: flex;
        width: 100%;
        margin-top: 10px;
        
    }
    
    .containerAvendeSmall .buyComprar {
    background-color: #a1c862;
    color: white;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    font-size: 17.5px;
    
    width: 100%;
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    
    }
    
    .containerAvendeSmall .aftercheckediv {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    background-color: #ebb837;
    }

    .containerAvendeSmall .wicon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        /*margin-left: 3px;*/
      
        height: auto;
      
        border: 0px solid black;
      }
      
      .containerAvendeSmall .wicon img {
        width: 100%;
        height: auto;
      }
    
    .containerAvendeSmall .divprom {
        margin-left: 20px;
        width: 10.8%;
      }
      
      .containerAvendeSmall .prom {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 28px;
        gap: 3.8px;
        
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
        border-radius: 7px;
        margin-right: 3px;
        
        
        /**/
        background-color: #efde98;
        border: 0px solid #efcb70;
        border-left: 7px solid #efcb70;
        border-bottom: 7px solid #efcb70;
        /*border: 7px solid #efcb70;*/
        width: 100%;
      }
      
      .containerAvendeSmall .promdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 20px;
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
        width: 90%;
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      }
      
      .containerAvendeSmall .promdivbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        font-size: 20px;
        /*border: 3px solid white;*/
        border: 3px solid #efcb70;
        height: 75%;
        padding: 8px;
      
        font-weight: bold;
        font-family: Courier;
        background-color: #282c34;
      
        
      }
      
      .containerAvendeSmall .promdivbotbot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    
    .containerAvendeSmall .playButton {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAvendeSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAvendeSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAvendeSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .containerAvendeSmall .joGameButtonLink {
        text-decoration: none;
    }
}
