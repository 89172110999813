.containerProfile {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerProfileSmall {
    display: none;
}


.containerProfile .twomono {
    margin-right: 3px;
    font-size: 20.8px;
    font-family: Courier, monospace;
}

.containerProfile .secondtopmenuBPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-family: Courier, monospace;
    padding-bottom: 4px;
    margin-top: 10px;
    border-bottom: 4.4px solid white;
    width: 90%;
}

.containerProfile .buybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    margin-left: 3px;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerProfile .playbuybutton {
    background-color: #a1c862;
    color: black;
    text-align: center;
    font-size: 17.5px;
    text-decoration: none;
    width: 28.8%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
}

.containerProfile .logoNameBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10px;
}

.containerProfile .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }

.containerProfile .gcoin {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.containerProfile .gcoin img {
    width: 100%;
    height: auto;
}

.containerProfile .simplelogo {
    font-family: Courier;
    margin-left: 3px;
}

.containerProfile .topmenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 17px;
    border-bottom: 3px solid white;
    width: 90%;
}

.containerProfile .mepagePage {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap:10px;
    flex-direction: row;
    
    padding-top: 40px;
    border-bottom: 3px solid white;
    min-height: 80vh;
    width: 90%;
}

.containerTor .mepagePageTitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 3px solid white;
    margin-left: 24px;
}

.containerTor .gameMenuPage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.containerTor .gamePage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid white;
    border-radius: 10.1px;
    padding: 10px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    width: 30%;
    height: 15vh;
}

.containerProfile .logoName {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 100%;
    padding-bottom: 3px;
    font-weight: bold;
    font-family: Courier, monospace;
    margin-left: 10px;
}

.containerProfile .gameplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
    font-family: Courier;
    height: 37vh;
    width: 20%;
    margin-top: 24px;
    margin-left: 24px;
    border-radius: 3px;
    border: 3px solid white;
}

.containerProfile .gcoinlogoo {
    width: 15px;
    height: 15px;
    margin-left: 3px;
}
  
.containerProfile .gcoinlogoo img {
    width: 100%;
    height: auto;
}
  
.containerProfile .gcoinlogox {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 3px;
}
  
.containerProfile .gcoinlogox img {
    width: 100%;
    height: auto;
}

.containerProfile .divbalances {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 3px;
    border: 4.8px solid;
    height: 30vh;
}

.containerProfile .divwinrate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-family: Courier;
    gap:30px;
    border-radius: 3px;
    border: 4.8px solid;

    height: 30vh;
}

.containerProfile .winrate {
    display: flex;
    flex-direction: column;
    font-family: Courier;
    
}

.containerProfile .divbuttons {
    border-radius: 3px;
    /*border: 4.8px solid;*/
    gap: 10px;
    flex-direction: column;
    display: flex;
    
    width: 40%;
   
}

.containerProfile .divbuybutton {
    background-color: #dab66d;
    color: #282c34;
    text-align: center;
    font-size: 14.5px;
    text-decoration: none;
    width: 90%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ae8d4b;
    font-weight: bold;
}

.containerProfile .leftmepagePage {
    display: flex;
    flex-direction: column;
    gap:10px;
    /*border: 3px solid;*/
     
    width: 50%;
    
}

.containerProfile .cuentaPageBalance {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20.8px;
    font-family: Courier, monospace;
    margin-left: 3px;
}

.containerProfile .beta {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    width: 15%;
    background-color: #da9f67;
}

.containerProfile .gamelogo {
    width: 58%;
    height: auto;
    min-width: 24px;
    min-height: 15vh;
    margin-top: 10px;
    margin-bottom: 0px;
}

.containerProfile .gamelogo img {
    width: 100%;
    height: auto;
}

.containerProfile .cuentaPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    width: 40%;
}

.containerProfile .playButton {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    margin-top: 48px;
    height: 20px;
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerProfile .joGameButton {
    background-color: #efcb70;
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-size: 17.5px;
    width: 44%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerProfile .createButtonPage {
    background-color: #efcb70;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    height: 100%;
    width: 40%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerProfile .mono {
    margin-right: 3px;
    font-size: 23px;
    font-family: monospace;
}

.containerProfile .loginButtonPage {
    background-color: #a1c862;
    color: #000;
    text-align: center;
    font-size: 17.5px;
    margin-left: 3px;
    height: 100%;
    width: 20%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #79b13d;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.containerProfile .joGameButtonLink {
    text-decoration: none;
}

@media (max-width: 768px) { 

    .containerProfileSmall {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .containerProfile {
        display: none;
    }
    
    .containerProfileSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .containerProfileSmall .gcoin img {
        width: 100%;
        height: auto;
    }

    .containerProfileSmall .glogos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    
        gap: 3px;
    
      }
      
      .containerProfileSmall .gcoinlogoo {
        width: 15px;
        height: 15px;
        
      }
      
      .containerProfileSmall .gcoinlogoo img {
        width: 100%;
        height: auto;
      }
      
      .containerProfileSmall .gcoinlogox {
        width: 15px;
        height: 15px;
        
      }

      .containerProfileSmall .gcoin {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 3px;
        margin-right: 3px;
      }
      
      .containerProfileSmall .gcoin img {
        width: 100%;
        height: auto;
      }
    
      .containerProfileSmall .namelogo {
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        margin-left: 4.8px;
      }

      .containerProfileSmall .divbalances {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 3px;
        border: 4.8px solid;
        margin-top: 20px;
        width: 90%;
        height: 20vh;
    }
    
    .containerProfileSmall .divwinrate {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-family: Courier;
        gap:30px;
        border-radius: 3px;
        border: 4.8px solid;
        width: 90%;
        height: 20vh;
    }
    
    .containerProfileSmall .winrate {
        display: flex;
        flex-direction: column;
        font-family: Courier;
        
    }
    
    .containerProfileSmall .divbuttons {
        border-radius: 3px;
        /*border: 4.8px solid;*/
        gap: 10px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 95%;
       
    }
    
    .containerProfileSmall .divbuybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 14.5px;
        text-decoration: none;
        width: 90%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }

      .containerProfileSmall .playbuybutton {
        background-color: #a1c862;
        color: black;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
    }
      
      .containerProfileSmall .gcoinlogox img {
        width: 100%;
        height: auto;
      }


      .containerProfileSmall .logoutButton
    {background-color: #efcb70;
    color: #000;
    margin-left: 10px;
    text-align: center;
    font-size: 17.5px;
    width: 25%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    }

      .containerProfileSmall .buybutton {
        background-color: #dab66d;
        color: #282c34;
        text-align: center;
        font-size: 17.5px;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ae8d4b;
        font-weight: bold;
    }
    
    .containerProfileSmall .simplelogo {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 3px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }
    
    .containerProfileSmall .topmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 17px;
        border-bottom: 3px solid white;
        width: 90%;
    }

    .containerProfileSmall .cuentaPageBalance {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerProfileSmall .mono {
        margin-right: 3px;
        font-size: 23px;
        font-family: monospace;
    }

    .containerProfileSmall .buttonscuentaPage {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerProfileSmall .cuentaPageName {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        font-size: 20.8px;
        font-family: Courier, monospace;
        margin-left: 3px;
    }

    .containerProfileSmall .logoNameBot {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-left: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerProfileSmall .secondtopmenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 4px;
        margin-top: 3px;
        width: 90%;
    }

    .containerProfileSmall .cuentaPage {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        width: 40%;
    }

    .containerProfileSmall .twomono {
        margin-right: 3px;
        font-size: 20.8px;
        font-family: Courier, monospace;
    }
      
    .containerProfileSmall .beta {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 4.8px;
        padding-left: 3px;
        padding-right: 3px;
        width: auto;
        background-color: #da9f67;
    }

    .containerProfileSmall .mepagePage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        gap:10px;
        min-height: 80vh;
        width: 90%;
    }

    .containerProfileSmall .mepagePageTitle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 3px solid white;
        margin-left: 24px;
    }

    .containerProfileSmall .gameMenuPage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }

    .containerProfileSmall .gamePage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px solid white;
        border-radius: 10.1px;
        padding: 10px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 24px;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
        width: 30%;
        height: 15vh;
    }

    .containerProfileSmall .logoName {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        width: 100%;
        padding-bottom: 4.4px;
        font-weight: bold;
        font-family: Courier, monospace;
        margin-left: 10px;
    }

    .containerProfileSmall .gameplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 20px;
        font-family: Courier;
        height: 37vh;
        width: 50%;
        margin-top: 24px;
        margin-left: 24px;
        border-radius: 3px;
        border: 3px solid white;
    }

    .containerProfileSmall .gamelogo {
        width: auto;
        height: auto;
        min-width: 24px;
        min-height: 15vh;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .containerProfileSmall .gamelogo img {
        width: auto;
        height: 100%;
        object-fit: contain;
    }

    .containerProfileSmall .playButton {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        margin-top: 48px;
        height: 20px;
        width: 80%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerProfileSmall .joGameButton {
        background-color: #efcb70;
        color: #000;
        margin-top: 15px;
        text-align: center;
        font-size: 17.5px;
        width: 44%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerProfileSmall .createButtonPage {
        background-color: #efcb70;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #ebb837;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerProfileSmall .loginButtonPage {
        background-color: #a1c862;
        color: #000;
        text-align: center;
        font-size: 17.5px;
        margin-left: 3px;
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        border-top: 0px;
        border-right: 0px;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: #79b13d;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }

    .containerProfileSmall .joGameButtonLink {
        text-decoration: none;
    }
}
