.containerPageGame {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containerPageGameSmall {
  display: none;
}

.containerPageGame .gamebo {
  border-radius: 3px;

  border-top: 3px solid white;
  border-right: 3px solid white;
  border-left: 4px solid white;
  border-bottom: 4px solid white;


  height: 35vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 90vmin; /* Ensures the board does not get too large */
  max-height: 90vmin;
  /*aspect-ratio: 1;*/
}


.containerPageGame .bo {
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  /*gap: 7px;*/
  gap: 7px;
  /*border: 10px solid white;*/
  width: 95%;
  height: auto;

  
  
  }
  
  .containerPageGame .cell {
    /*width: 100%;*/
    width: 100%;
  height: 10vh;

  
  
  background-color: #fff;
  color: #000;
  font-size: 40px;
  
  
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-weight: bold;

  border-left: 4.5px solid #d5a077;
  border-bottom: 4.5px solid #d5a077;
  /*text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #00f, 0 0 30px #00f, 0 0 40px #00f, 0 0 50px #00f, 0 0 60px #00f, 0 0 70px #00f;
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #00f;
  transition: background-color 0.3s ease;*/
  /*box-shadow: 0 4px #43557a;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;*/

   /* border: none;*/
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(26, 62, 96, 0.71); /* Elevated shadow */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    
  }

  .gameidGame
  

  .containerPageGame .cell:active {
    background-color: #d5a077; /* Darker color on click */
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
}

.containerPageGame .cell:hover {
    border-left: 7px solid #d5a077;
    border-bottom: 7px solid #d5a077;
    transform: translateY(2px);
}
  
  .containerPageGame .cell:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  .containerPageGame .bot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 10px;

    width: 45%;
    height: 15vh;

    border-radius: 3px;
    border-top: 6.1px solid #efcb70;
    border-right: 6.1px solid #efcb70;
    border-left: 7px solid #efcb70;
    border-bottom: 7px solid #efcb70;
  }

  .containerPageGame .botdivtop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;

    width: 100%;
    
    /*border: 3px solid white;*/
  }

  .containerPageGame .gameidGame {
    margin-top: 12px;


    font-family: Courier, monospace;
    font-weight: bold;
  }
  .containerPageGame .gameplayersGame {
    margin-top: 1px;
    margin-bottom: 12px;

    font-family: Courier, monospace;
  }



  .containerPageGame .botdivbottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 100%;
    min-width: 350px;

    margin-top: 10px;
   
    
    /*border: 3px solid white;*/
  }
  
  .containerPageGame .winner {
    margin-top: 20px;
    font-size: 24px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    padding: 20px;

    width: 100%;
    min-width: 350px;


    border-radius: 3px;

    

    border-top: 6.1px solid #a1c862;
    border-right: 6.1px solid #a1c862;
    border-left: 7px solid #a1c862;
    border-bottom: 7px solid #a1c862;
  }

  .containerPageGame .belowbot {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    padding: 20px;

    width: 46%;
    min-width: 350px;

    margin-top: 10px;


    border-radius: 3px;

    height: 7vh;

    

    /*border-top: 6.1px solid #a1c862;
    border-right: 6.1px solid #a1c862;
    border-left: 7px solid #a1c862;
    border-bottom: 7px solid #a1c862;*/
  }


.containerPageGame .playerdiv {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    font-family: Courier, monospace;
    font-weight: bold;

    width: 80%;
    height: 35vh;

    border: 3px solid white;

    word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;

  border-radius: 7px;

    border-top: 3px solid white;
    border-right: 3px solid white;
    border-left: 4px solid white;
    border-bottom: 4px solid white;

    margin-right: 3px;
    margin-left: 3px;
}

.containerPageGame .gamediv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 90%;


    /*border: 3px solid white;*/

    /*border-radius: 3px;

    border-top: 3px solid white;
    border-right: 3px solid white;
    border-left: 10px solid white;
    border-bottom: 10px solid white;*/

}
.containerPageGame .restartButton {
  
    
  text-align: center;
  font-size: 17.5px;
  
  width: 100%;
  padding: 10px;

  border-radius: 4px;
  cursor: pointer;

  border-top: 0px;
  border-right: 0px;
  border-bottom: 3.5px solid;
  border-left: 3.5px solid;
  border-color: #728da7;

  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
    
    
    color: #fff;
    background-color: #42658a;


    

    /*box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #d5a077, 0 0 30px #d5a077;*/
  }
  
  .containerPageGame .restartButton:hover {
    background-color: #4975a4;
  }





  .containerPageGame .exitButton {
    background-color: #efcb70;
    color: #000;
    
    
    margin-left: 3px;
    
    text-align: center;
    font-size: 17.5px;
    
    width: 40%;
    padding: 10px;

    border-radius: 4px;
    cursor: pointer;

    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #ebb837;

    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }

  @media (max-width: 768px) { 
    .containerPageGameSmall {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-size: calc(10px + 2vmin);
      color: white;
  }
  
  .containerPageGame {
    display: none;
  }
  
  .containerPageGameSmall .gamebo {
    border-radius: 3px;
  
    border-top: 3px solid white;
    border-right: 3px solid white;
    border-left: 4px solid white;
    border-bottom: 4px solid white;
  
  
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    max-width: 90vmin; /* Ensures the board does not get too large */
    max-height: 90vmin;
    /*aspect-ratio: 1;*/
  }
  
  
  .containerPageGameSmall .bo {
      display: grid;
    grid-template-columns: repeat(3, 1fr);
    /*gap: 7px;*/
    gap: 7px;
    /*border: 10px solid white;*/
    width: 95%;
    height: auto;
  
    
    
    }
    
    .containerPageGameSmall .cell {
      /*width: 100%;*/
      width: 100%;
    height: 10vh;
  
    
    
    background-color: #fff;
    color: #000;
    font-size: 40px;
    
    
    display: flex;
    align-items: center;
    justify-content: center;
  
    cursor: pointer;
  
    font-weight: bold;
  
    border-left: 4.5px solid #d5a077;
    border-bottom: 4.5px solid #d5a077;
    /*text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #00f, 0 0 30px #00f, 0 0 40px #00f, 0 0 50px #00f, 0 0 60px #00f, 0 0 70px #00f;
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #00f;
    transition: background-color 0.3s ease;*/
    /*box-shadow: 0 4px #43557a;
      transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;*/
  
     /* border: none;*/
      border-radius: 10px; /* Rounded corners */
      box-shadow: 0 4px 6px rgba(26, 62, 96, 0.71); /* Elevated shadow */
      transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
      
    }
  
    .gameidGame
    
  
    .containerPageGameSmall .cell:active {
      background-color: #d5a077; /* Darker color on click */
      transform: translateY(2px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
  }
  
  .containerPageGameSmall .cell:hover {
      border-left: 7px solid #d5a077;
      border-bottom: 7px solid #d5a077;
      transform: translateY(2px);
  }
    
    .containerPageGameSmall .cell:disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }
  
    .containerPageGameSmall .bot {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      margin-top: 10px;
  
      width: 45%;
      height: 15vh;
  
      border-radius: 3px;
      border-top: 6.1px solid #efcb70;
      border-right: 6.1px solid #efcb70;
      border-left: 7px solid #efcb70;
      border-bottom: 7px solid #efcb70;
    }
  
    .containerPageGameSmall .botdivtop {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      font-weight: bold;
      font-family: 'Courier New', Courier, monospace;
  
      width: 100%;
      
      /*border: 3px solid white;*/
    }
  
    .containerPageGameSmall .gameidGame {
      margin-top: 12px;
  
  
      font-family: Courier, monospace;
      font-weight: bold;
    }
    .containerPageGameSmall .gameplayersGame {
      margin-top: 1px;
      margin-bottom: 12px;
  
      font-family: Courier, monospace;
    }
  
  
  
    .containerPageGameSmall .botdivbottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
  
      width: 100%;
      min-width: 350px;
  
      margin-top: 10px;
     
      
      /*border: 3px solid white;*/
    }
    
    .containerPageGameSmall .winner {
      margin-top: 0px;
      font-size: 24px;
      color: white;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      flex-direction: column;
  
      padding: 20px;
  
      width: 100%;
      min-width: 350px;
  
      height: 1.5vh;

      font-family: Courier, monospace;
  
      border-radius: 3px;
  
      
  
      border-top: 6.1px solid #a1c862;
      border-right: 6.1px solid #a1c862;
      border-left: 7px solid #a1c862;
      border-bottom: 7px solid #a1c862;
    }
  
    .containerPageGameSmall .belowbot {
      display: flex;
      justify-content: center;
      align-items: center;
  
      flex-direction: column;
      padding: 20px;
  
      width: 90%;
      min-width: 350px;
  
      margin-top: 10px;
  
      /*border: 1px solid;*/
  
      border-radius: 3px;
  
      height: 1.5vh;
  
      
  
      /*border-top: 6.1px solid #a1c862;
      border-right: 6.1px solid #a1c862;
      border-left: 7px solid #a1c862;
      border-bottom: 7px solid #a1c862;*/
    }
  
  
  .containerPageGameSmall .playerdiv {
      display: flex;
      justify-content: center;
      align-items: center;
  
      text-align: center;
  
      font-family: Courier, monospace;
      font-weight: bold;
  
      width: 90%;
      height: 10vh;
  
      border: 3px solid white;
  
      word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  
    border-radius: 7px;
  
      border-top: 3px solid white;
      border-right: 3px solid white;
      border-left: 4px solid white;
      border-bottom: 4px solid white;
  
      margin-right: 3px;
      margin-left: 3px;
  }
  
  .containerPageGameSmall .gamediv {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      width: 100%;
  
  
      /*border: 3px solid white;*/
  
      /*border-radius: 3px;
  
      border-top: 3px solid white;
      border-right: 3px solid white;
      border-left: 10px solid white;
      border-bottom: 10px solid white;*/
  
  }
  .containerPageGameSmall .restartButton {
    
      
    text-align: center;
    font-size: 17.5px;
    
    width: 100%;
    padding: 10px;
  
    border-radius: 4px;
    cursor: pointer;
  
    border-top: 0px;
    border-right: 0px;
    border-bottom: 3.5px solid;
    border-left: 3.5px solid;
    border-color: #728da7;
  
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
      
      
      color: #fff;
      background-color: #42658a;
  
  
      
  
      /*box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #d5a077, 0 0 30px #d5a077;*/
    }
    
    .containerPageGameSmall .restartButton:hover {
      background-color: #4975a4;
    }
  
  
  
  
  
    .containerPageGameSmall .exitButton {
      background-color: #efcb70;
      color: #000;
      
      
      margin-left: 3px;
      
      text-align: center;
      font-size: 17.5px;
      
      width: 40%;
      padding: 10px;
  
      border-radius: 4px;
      cursor: pointer;
  
      border-top: 0px;
      border-right: 0px;
      border-bottom: 3.5px solid;
      border-left: 3.5px solid;
      border-color: #ebb837;
  
      font-weight: bold;
      font-family: 'Courier New', Courier, monospace;
    }

  }